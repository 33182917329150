import React, {  useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../utils/UserContext';
import { Spinnerx } from '../components';
import { useQuery } from "@apollo/client";
import { Container, Row, Col, Button } from "reactstrap";
// import { PencilSquare } from 'react-bootstrap-icons';
import { USER_PROFILE } from "../utils/queries";

function UserProfile() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    // If user not loggedin sent to login page
    if(!user) {
    navigate('/login');
  }
  }, [user, navigate]);

  // User logout function
  const logout = () => {
    localStorage.clear();
    setUser(null);
    navigate('/login');
  }


  const { loading, error, data } = useQuery(USER_PROFILE);
  if (error) return "Error Loading User Account";
  if (loading) return <Spinnerx message="Fetching..." />;
// console.log(data);
// console.log(data.me.username);
if (data.me)
{


  return (
    <Container className="g-0 p-0">
    <Row className=" m-2 min-vh-100 bg-white shadow">
      <Col>
      <h3 className="pt-3 ">UserProfile</h3>
      <p>
        Username: {data.me.username}
        <br />
        Email Address: {data.me.email}
      </p>
      
        
        <Button className="bg-light text-dark">Change Password</Button> <br /> 
        <Button className="bg-light text-dark my-2" onClick={logout}>Logout</Button> <br />
        <Button className="bg-light text-dark">Account Delete</Button>
        </Col>
      </Row>
    </Container>
  )
} else {
  return <h3>Error loading profile</h3>;
}
}

export default UserProfile