import React, { useState } from 'react';
import {
  Container, Card, CardBody, CardImg, CardText, CardTitle,
  Col, Row,
  InputGroup, Input, FormGroup, Button,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { CartPlus, DashLg, PlusLg, } from 'react-bootstrap-icons';
// import { cartItemsVar } from '../utils/apolloclient';
// import { useReactiveVar } from '@apollo/client';

function CalcDiscount(val) {
  const dprice = parseFloat(val.dp);
  const oprise = parseFloat(val.op);
  const discountp = Math.round(((dprice - oprise) / oprise) * 100);
  return discountp;
}

function RestaurantMenu(props) {
  const searchQuery = props.restaurant;
  const currency = props.currency || " ";
  const cartItems = props.cartItems;
  const cartItemsVar = props.cartItemsVar;
  // const cartItems = cartItemsVar();
  // let cartItems = useReactiveVar(cartItemsVar);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [dishqty, setDishqty] = useState(null);
  const [modalshow, setModalshow] = useState(false);
  const handleModalShow = () => setModalshow(!modalshow);
  const DISH_VARIANT_STATE = {
    "variantName": "",
    "price": 0,
    "discountPrice": 0,
    "default": 0
  };
  const [dishvariant, setDishvariant] = useState(DISH_VARIANT_STATE);

  return (
    <>

      <Container className="py-3 px-2">

        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
          {(searchQuery.length !== 0) ?


            searchQuery.map((res) => (
              <>
                {(res.attributes.menutype === 0) ?
                  <Row key={res.id} className="w-100" id={res.attributes.name.replace(/\s+/g, '-').toLowerCase()}>
                    <p className="h6 ps-3 pt-3 m-0 pb-0 text-muted ">
                      {res.attributes.name}
                    </p>
                  </Row>
                  :
                  <>
                    < Col key={res.id} className="border-bottom pb-3 ">
                      <Card className="p-0  bg-transparent border-0 ">
                        <Row className="g-0">
                          {
                            res.attributes.image.data ?
                              <Col xs="3" className=" p0">
                                <CardImg
                                  top={true}
                                  className="h-100"
                                  src={`${process.env.REACT_APP_API_URL}${res.attributes.image.data.attributes.url}`}
                                />
                              </Col> :
                              <></>
                          }
                          <Col className="p-0 ps-2 pe-1">
                            <CardBody className="p-0">
                              <Row className="pt-2">
                                {/* <Col xs="8"> */}
                                <CardTitle tag="h6" className="fw-bolder ">
                                  {res.attributes.name}

                                </CardTitle>
                              </Row>

                              <CardText className="text-break text-wrap small p-0 mb-0" >
                                {res.attributes.description.substring(0, 70)}{(res.attributes.description.length > 70) ? "..." : null}
                              </CardText>
                              <div className="mb-0 pe-2 align-items-middle"
                                tag="h6"
                              >

                                <span className="pe-3"><img src={`/images/${res.attributes.isveg}.jpg`} width="14" height="14" alt="" /></span>
                                {res.attributes.spicelevel &&
                                  <span className="border rounded-3  "><img src={`/images/${res.attributes.spicelevel}.jpg`} width="24" height="12" alt="" /> </span>
                                }

                                {res.attributes.price1 ?
                                  res.attributes.price1.map((price, index) => (
                                    price.default ?

                                      <div key={index} className="float-end">
                                        {price.discountPrice !== price.price ?
                                          <><span className="mx-2 small bg-warning rounded-3 px-1">  <CalcDiscount dp={price.discountPrice} op={price.price} />% </span>
                                            <span className="text-decoration-line-through "><small>{currency} {price.price}</small></span>
                                            <span className="px-2 fw-bolder"><small>{currency} </small>{price.discountPrice}</span></>
                                          :
                                          <span className="px-2 fw-bolder"><small>{currency} </small>{price.price}</span>
                                        }
                                        <Button className="p-0 px-1 bg-transparent text-primary mb-2 shadow-sm " onClick={() => { setSelectedItemId(res); handleModalShow(); setDishqty("1"); setDishvariant(price); }}>

                                          <CartPlus className="" />{cartItems?.some(item => item.dishid === res.id) ?
                                            <span className="bg-secondary text-light rounded-3 ms-1 px-1">{(cartItems.reduce((totQty, item) => item.dishid === res.id ? totQty = totQty + parseInt(item.qty) : totQty, 0))}</span>
                                            : ""
                                          } </Button>
                                      </div>
                                      // price.discountPrice !== price.price ?
                                      //   <div key={index} className="float-end">
                                      //     <span className="mx-2 small bg-warning rounded-3 px-1">  <CalcDiscount dp={price.discountPrice} op={price.price} />% </span>
                                      //     <span className="text-decoration-line-through "><small>{currency} {price.price}</small></span>
                                      //     <span className="px-2 fw-bolder"><small>{currency} </small>{price.discountPrice}</span>
                                      //     {/* <span className="text-decoration-line-through fw-lighter"><small>{currency} {res.attributes.price}</small></span> */}
                                      //     {/* <span className="px-2 fw-bolder"><small>{currency} </small>{res.attributes.price}</span> */}
                                      //     <Button className="p-0 px-1 bg-transparent text-primary mb-2 shadow-sm" onClick={() => { setSelectedItemId(res); handleModalShow(); setDishqty("1"); setDishvariant(price); }}><CartPlus className="" /></Button>
                                      //   </div>
                                      //   :
                                      //   <div key={index} className="float-end">
                                      //     <span className="px-2 fw-bolder"><small>{currency} </small>{price.price}</span>
                                      //     {/* <span className="text-decoration-line-through fw-lighter"><small>{currency} {res.attributes.price}</small></span> */}
                                      //     {/* <span className="px-2 fw-bolder"><small>{currency} </small>{res.attributes.price}</span> */}
                                      //     <Button className="p-0 px-1 bg-transparent text-primary mb-2 shadow-sm" onClick={() => { setSelectedItemId(res); handleModalShow(); setDishqty("1"); setDishvariant(price); }}><CartPlus className="" /></Button>
                                      //   </div>

                                      : ""

                                  ))
                                  : ""}
                              </div>
                            </CardBody>
                          </Col>
                        </Row>

                      </Card>

                    </Col>
                  </>


                }
              </>
            ))

            :
            <h2>No Dishes Found</h2>
          }
        </Row>

      </Container>

      {
        modalshow && (
          <Modal
            centered
            fullscreen="sm"
            fade={true}
            isOpen={modalshow}
            toggle={handleModalShow}
            className="h-auto align-bottom"
          >

            <ModalHeader toggle={handleModalShow} >
              <span className="pe-3"><img src={`/images/${selectedItemId.attributes.isveg}.jpg`} width="14" height="14" alt="" /></span>{selectedItemId.attributes.name}
            </ModalHeader>
            <ModalBody>
              {/* {
              selectedItemId.attributes.image.data ?
                <CardImg
                  top={true}
                  className="h-auto w-auto"
                  src={`${process.env.REACT_APP_API_URL}${selectedItemId.attributes.image.data.attributes.url}`}
                />
                :
                <></>
            } */}
              <p>
                {selectedItemId.attributes.description}

                {selectedItemId.attributes.spicelevel &&
                  <span className="border rounded-3  "><img src={`/images/${selectedItemId.attributes.spicelevel}.jpg`} width="24" height="12" alt="" /> </span>
                }
              </p>
              <div className="border-bottom pb-2">

                {selectedItemId.attributes.price1 ?
                  selectedItemId.attributes.price1.map((price, index) => (

                    <FormGroup check key={index}>
                      <Input
                        name="dishVariant"
                        type="radio"
                        // value={0}
                        // defaultChecked
                        defaultChecked={dishvariant.default === price.default ? true : false}
                        onChange={() => setDishvariant(price)}
                      />
                      {/* {console.log(dishvariant.default)} */}
                      <Row className="d-flex w-100 my-3">

                        {price.discountPrice !== price.price ?
                          <div key={index} className=" ">
                            <span>{price.variantName}</span>
                            <span className="float-end">
                              <span className="mx-2 small bg-warning rounded-3 px-1">  <CalcDiscount dp={price.discountPrice} op={price.price} />% </span>
                              <span className="text-decoration-line-through fw-lighter px-1"><small>{currency} {price.price}</small></span>
                              <span className="px-2 fw-bolder"><small>{currency} </small>{price.discountPrice}</span>
                            </span>
                          </div>
                          :
                          <div key={index} className="">
                            <span>{price.variantName}</span>
                            <span className="px-2 fw-bolder float-end"><small>{currency} </small>{price.price}</span>
                          </div>}
                      </Row>
                    </FormGroup>
                  ))
                  : ""}

                {/* <span className="mx-2 small bg-warning rounded-3 float-end px-1"> -20%</span>
                <span className="fw-bolder pe-3"><small>{currency} </small>{selectedItemId.attributes.price}</span>
                <span className="text-decoration-line-through "><small>{currency} {selectedItemId.attributes.price}</small></span> */}
                {/* <Button className="p-0 px-1 bg-transparent text-primary mb-2 shadow-sm" onClick={() => {setSelectedItemId(res); handleModalShow(); } }><CartPlus className="" /></Button> */}
              </div>
              <div className="  d-inline-flex py-3">

                <InputGroup size="sm" className="w-50" >
                  <Button outline
                    bssize="sm"
                    className="ms-4"
                    onClick={() => setDishqty(parseInt(dishqty) - 1)}
                    disabled={(parseInt(dishqty) === 1)}
                  // { {dishqty} === 1 ? disabled : null }
                  >
                    <DashLg />
                  </Button>
                  {/* <span>{dishqty}</span> */}
                  <Input
                    id="dishQty"
                    name="dishQty"
                    placeholder="0"
                    type="text"
                    disabled
                    bssize="sm"
                    className="text-center fw-bold"
                    value={dishqty}
                  // hidden

                  // onChange={e => updateQuery(e.target.value.toLocaleLowerCase())}
                  //           value={query}
                  />
                  <Button outline
                    bssize="sm" className="me-5"
                    onClick={() => setDishqty(parseInt(dishqty) + 1)}
                  >
                    <PlusLg />
                  </Button>

                </InputGroup>



                {' '}

                {' '}
                <Button className="btn-sm float-end fw-bolder"
                  // onClick={() => handleAddToCart(res, {})}
                  onClick={() => {
                    // const handleAddToCart = () => {
                    const itemInCartIndex = cartItems?.findIndex(item => item.dishid === selectedItemId.id && item.dishvariant === dishvariant.variantName);

                    if (itemInCartIndex >= 0) {
                      let newcartItems = cartItemsVar();
                      let updateItem = newcartItems[itemInCartIndex];
                      updateItem.qty = parseInt(updateItem.qty) + parseInt(dishqty);
                      newcartItems.splice(itemInCartIndex, 1);
                      // console.log(newcartItems);
                      cartItemsVar([...newcartItems, updateItem]);
                    }
                    else {
                      const newItem = {
                        dishid: selectedItemId.id,
                        dishname: selectedItemId.attributes.name,
                        // dishprice: selectedItemId.attributes.price,
                        dishvariant: dishvariant.variantName,
                        dishprice: dishvariant.price,
                        dishdprice: dishvariant.discountPrice,
                        qty: dishqty,
                        comment: "",
                        // isSelected: false,
                      };
                      cartItemsVar([...cartItemsVar(), newItem]);
                    }




                    handleModalShow();

                  }}
                >
                  {/* <CartPlus />  */}
                  Add < small className="ps-2">{currency} </small> {parseInt(dishqty) * parseFloat(dishvariant.discountPrice)}
                  {/* {dishvariant.discountPrice ? parseInt(dishqty) * parseFloat(dishvariant.discountPrice) : parseInt(dishqty) * parseFloat(dishvariant.price) } */}
                </Button>
                {/* { console.log(cartItemsVar()) } */}
              </div>
            </ModalBody>




          </Modal>
        )
      }



    </>
  );


}

export default RestaurantMenu;
