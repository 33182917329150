import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Container, Table, Row, Col, Form, Label, Input, Button } from "reactstrap";
import { PencilSquare, PlusSquareFill } from 'react-bootstrap-icons';
//import moment from "moment";
import { Spinnerx } from '.';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_DISH_CATEGORY, UPDATE_DISH_CATEGORY } from '../utils/mutation';
import { GET_RESTAURANT_DISHES_BYID } from "../utils/queries";


const INITIAL_FORM_STATE = {
  name: "",
  dcuuid: "",
  restaurant: "",
};

function MenuCategory(props) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [modalshow, setModalshow] = useState(false);
  const [modaltype, setModaltype] = useState();
  const handleModalShow = () => setModalshow(!modalshow);
  const restaurantID = props.restaurantID;
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  // console.log(restaurantID);

  // Create Restaurant Menu Category
  const [createDishCategory, { loading: createLoading, error: createError }] =
    useMutation(CREATE_DISH_CATEGORY, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_DISHES_BYID,
          variables: { id: restaurantID },
        },
      ],
    });

  const [updateDishCategory, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_DISH_CATEGORY, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_DISHES_BYID,
          variables: { id: restaurantID },
        },
      ],
    });

  const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES_BYID, {
    variables: { id: restaurantID },
  });
  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;
  if (data.restaurant.data) {
    const { restaurant } = data;

    const tableData = data.restaurant.data.attributes.dishcategories.data;
    const tableHeader = [
      // "ID",
      "Dish Category Name",

    ];
    // Form data change/ update
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }

    async function handleAddSubmit(event) {
      event.preventDefault();

      const data = {
        name: formData.name.trim(),
        restaurant: parseInt(restaurantID),
        dcuuid: uuidv4()
      };

      await createDishCategory({
        variables: { data }

      });
      handleModalShow();
      // setFormData(INITIAL_FORM_STATE);
    }

    async function handleEditSubmit(event) {
      event.preventDefault();
      await updateDishCategory({
        variables: {
          id: selectedItemId,
          data: {
            name: formData.name.trim()
          },
        },
      });
      handleModalShow();

    }

    // Open Edit Items dialog / modal
    function handleShowModal(dishcategory) {
      // setIsVisible(true);
      if (dishcategory) {
        setSelectedItemId(dishcategory.id);
        setFormData({
          ...formData,
          name: dishcategory.attributes.name   
        });
        setModaltype("edit");
      }
      else {
        setModaltype("add");
        setFormData({
          ...formData,
          name: ""   
        });
      } 
      handleModalShow();
    }

    return (
      <Container className="bg-white ">
        <Row>
          <div className="mt-3 "> Menu Category contains {tableData.length} items
            <PlusSquareFill className="h3 float-end text-primary" onClick={() => handleShowModal()} />
          </div>
        </Row>
        <Row>
          <Col>
            {
              modalshow ?
                <Col className=" rounded shadow bg-light p-3 my-4 d-flex align-items-center">

                  <button className="float-start btn-close small me-2 " onClick={() => setModalshow(false)}>  </button>
                  {/* <h5 className="text-muted">Add New Dish </h5> */}
                  {(modaltype === "edit") ?
                    <>

                      {
                        createError &&
                        <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
                      }

                      <Form inline onSubmit={handleEditSubmit} className="d-flex mx-2">
                        <Label for="dishCategoryName" hidden > Dish Category Name </Label>
                        <Input
                          id="dishCategoryName"
                          name="name"
                          placeholder="Add Menu Category Name"
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <Button type="submit" className="bg-primary rounded-3 shadow-none ms-3">Save </Button>
                      </Form>
                    </>
                    :
                    <>
                      {
                        updateError &&
                        <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
                      }

                      <Form inline onSubmit={handleAddSubmit} className="d-flex mx-2">
                        <Label for="dishCategoryName" hidden > Dish Category Name </Label>
                        <Input
                          id="dishCategoryName"
                          name="name"
                          placeholder="Add Menu Category Name"
                          type="text"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <Button type="submit" className="bg-primary rounded-3 shadow-none ms-3">Add </Button>
                      </Form>
                    </>
                  }
                </Col>

                : <></>
            }
          </Col>
        </Row>
        <Row>

          <Table responsive>
            <thead>
              <tr>
                {tableHeader.map((header, index) => (
                  <th key={index}>
                    {header}
                  </th>
                ))}
                <th className="visually-hidden">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {tableData.map((dishcategory) => (
                <tr key={dishcategory.id}>
                  {/* <td>{dish.id}</td> */}
                  <td>{dishcategory.attributes.name}</td>

                  {/* <td>
                     {moment(dish.attributes.updatedAt).format("MMM Do YY")} 
                  </td> */}
                  <td>
                    {/**  <PencilSquare className="h4" onClick={() => handleShowModal(dish.id)} /> */}
                    <PencilSquare className="h4" onClick={() => handleShowModal(dishcategory)} />

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>

      </Container>
    )
  }
}

export default MenuCategory