import React from 'react';
import {  useNavigate } from 'react-router-dom';
// import { useQuery } from "@apollo/client";
// import { QRCodeGen, NotFound404, MenuItems, RestaurantSettings, RestaurantDashboard, RestaurantInfo, Spinnerx, Header, Footer, RestaurantMenu } from '.';
import { Container, Col, Row, Button } from "reactstrap";
// import { GET_RESTAURANT_DISHES } from "../utils/queries";
// import { PencilSquare, ToggleOff, ToggleOn, PlusSquareFill } from 'react-bootstrap-icons';


function FeaturesPortal() {
    const navigate = useNavigate();
    return (
        <Container className="bg-light mx-2 w-auto">
      <Row className=" d-flex  justify-content-center ">
        <Col sm="4" className="rounded shadow bg-white p-3 m-3">
            <h1>Feature Request</h1>
                <p className='text-muted h6 mb-3'>Use this section to request new features in FoodPle platform. FoodPle product team will evaluate the feature and implement / prioritize as per the internal policy. 
                <br /> <br />
                We are updating this section and is not available at this moment. Please check back soon. Thanks </p>
                <Button className="align-center " onClick={() => navigate('/')} > Home </Button>
           </Col>
            </Row>
        </Container>
    )
}

export default FeaturesPortal