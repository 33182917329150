import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from "reactstrap";

function NotFound404() {
    return (
        <Container className="bg-light vh-100 ">
        <Row className="vh-100 justify-content-center align-items-center ">
            <Col className="col-md-4 rounded shadow-sm bg-white p-3 m-3 ">
            <p className="h4 text-center">404, Page not found</p>
        <p className="h6 text-center">Looks like the page you're looking for <br /> has been removed or doesn't exist.</p>
      <Link to="/">Return to Home Page</Link>
        </Col>
    
        </Row>
    </Container>);
}

export default NotFound404;
