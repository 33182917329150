import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { SIGNUP_USER } from '../utils/mutation';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { UserContext } from '../utils/UserContext';
import { Container, Row, Col, Button, NavLink, FormGroup, Form, Label, Input } from "reactstrap";
import { Spinnerx, SocialLink, Header } from '.';

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const { user, setUser } = useContext(UserContext);
  let location = useLocation();
  const [signupUser, { loading, error, data }] = useMutation(SIGNUP_USER);

  if (loading) return <Spinnerx message="Signing up..." />
  if (data) {
    const { login } = data;
    setUser({ token: login.jwt, userID: login.user.id });
    // localStorage.setItem("jwt", data.register.jwt)
    if(location.state?.returnpath)
   { 
     navigate(location.state.returnpath);
    
   } else
   {
     navigate("/");
   }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    signupUser({
      variables: {
        input: formData
      }
    })
  }
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  return (
    <Container className="m-0 p-0">
      <Header />

    
    <Container className="bg-light min-vh-100">

      <Row >
        <h2 className="text-center text-dark py-2">Get started with FoodPle</h2>
        <h5 className="text-center text-muted">Create a free account to start tracking your food orders and to add your restaurants.</h5>
        <p className="text-center text-muted small">No credit card required. Unsubscribe at anytime</p>
      </Row>
      <Row className=" d-flex align-items-center justify-content-center ">
        <Col className="col-md-4 rounded shadow bg-white p-3 flex m-3 ">
        <button className="float-start bg-transparent btn-close small me-2 pt-2" onClick={() => navigate('/')}>  </button>
          <h5 className="text-muted" >Signup </h5>

          {
            error &&
            <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
          }


          <Form inline onSubmit={handleSubmit}>

            <FormGroup>
              <Label
                for="signupName"
                hidden
              >
                Username
              </Label>
              <Input
                id="signupName"
                name="username"
                placeholder="Username"
                type="text"
                onChange={handleChange}
                required
              />
            </FormGroup>
            {' '}<FormGroup>
              <Label
                for="signupEmail"
                hidden
              >
                Email
              </Label>
              <Input
                id="signupEmail"
                name="email"
                placeholder="Email"
                type="email"
                onChange={handleChange}
                required
              />
            </FormGroup>
            {' '}
            <FormGroup>
              <Label
                for="signupPassword"
                hidden
              >
                Password
              </Label>
              <Input
                id="signupPassword"
                name="password"
                placeholder="Password"
                type="password"
                onChange={handleChange}
                required
              />
            </FormGroup>

            <Button type="submit" className="bg-primary rounded-3 w-100 shadow-none border-0">Create Account</Button>
          </Form>
          <NavLink tag={Link} to={"/login"} className="text-center mt3 text-muted small" >
            Already have Account? <b>Login</b>
            </NavLink>
          <nav>
            <Row className="justify-content-center g-0">
              <Col className="col-5"><hr /></Col>
              <Col className="col-1"><p className="text-center ">Or</p></Col>
              <Col className="col-5"><hr /></Col>
            </Row>
            <SocialLink />
          </nav>
          
        </Col>
        <p className="text-center text-muted small pt-2">By creating an account, you adhere to the <br /><Link to={"/terms-and-conditions"} >Terms Of Service</Link> and <Link to={"/privacy-policy"} > Privacy Policy</Link>.</p>
      </Row>
    </Container>
    </Container>
  )
}

export default Signup