import React, { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from '../utils/UserContext';
import { Container, Table, Row, Col, Form, Label, Input, Button, FormGroup } from "reactstrap";
import { PencilSquare, PlusSquareFill, Trash } from 'react-bootstrap-icons';
//import moment from "moment";
import { Spinnerx, NotFound404 } from '.';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_RESTAURANT_STAFF, UPDATE_RESTAURANT_STAFF } from '../utils/mutation';
import { GET_RESTAURANT_USERS_BY_RID_QUERY, GET_RESTAURANT_DISHES_BYID } from "../utils/queries";


const INITIAL_FORM_STATE = {
  username: "",
  emailaddress: "",
  phone: "",
  staffrole: "",
  userid: "",
  restaurant: "",
  rsuuid: "",
};

function RstaffForm({ formData, setFormData }) {
  // setFormData(formData);
  // console.log(formData);
  // Form data change/ update
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  return (
    <>
      <FormGroup>
        <Label
          for="adminName"
          hidden
        >
          Admin Name
        </Label>
        <Input
          id="adminName"
          name="username"
          placeholder="Staff Name"
          type="text"
          value={formData.username ?? ""}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {' '}<FormGroup>
        <Label
          for="adminEmail"
          hidden
        >
          Admin Email
        </Label>
        <Input
          id="adminEmail"
          name="emailaddress"
          placeholder="Staff Email"
          type="text"
          value={formData.emailaddress ?? ""}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {' '}<FormGroup>
        <Label
          for="adminPhone"
          hidden
        >
          Admin Phone
        </Label>
        <Input
          id="adminPhone"
          name="phone"
          placeholder="Staff Phone Number"
          type="text"
          value={formData.phone ?? ""}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {' '}<FormGroup>
        <Label
          for="staffRole"
          hidden
        >
          Staff Role
        </Label>
        <Input
          id="staffRole"
          name="staffrole"
          placeholder="Staff Role"
          type="select"
          value={formData.staffrole ?? ""}
          onChange={handleChange}
          required
        >
          <option value={2} >
            Staff
          </option>
          <option value={1}>
            Admin
          </option>
        </Input>
      </FormGroup>
    </>
  );
}

function RestaurantUsers(props) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { user } = useContext(UserContext);
  const [modalshow, setModalshow] = useState(false);
  const [modaltype, setModaltype] = useState();
  const handleModalShow = () => setModalshow(!modalshow);
  const restaurantID = props.restaurantID;
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  // console.log(restaurantID);

  // Create Restaurant Menu Category
  const [createRestaurantstaff, { loading: createLoading, error: createError }] =
    useMutation(CREATE_RESTAURANT_STAFF, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_USERS_BY_RID_QUERY,
          variables: { id: restaurantID },
        },
      ],
    });

  const [updateRestaurantstaff, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_RESTAURANT_STAFF, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_USERS_BY_RID_QUERY,
          variables: { id: restaurantID },
        },
      ],
    });

  const { loading, error, data } = useQuery(GET_RESTAURANT_USERS_BY_RID_QUERY, {
    variables: { id: restaurantID },
  });
  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;
  // console.log(data);

  if (data.restaurantstaffs.data) {
    const tableData = data.restaurantstaffs.data;
    const tableHeader = [
      // "ID",
      "User Name",
      "Email Address",
      "Phone",
      "Role",
      "Updated On"
    ];


    async function handleAddSubmit(event) {
      event.preventDefault();
      const data = {
        staffrole: parseInt(formData.staffrole),
        userid: user.userID,  
        restaurant: parseInt(restaurantID),
        rsuuid: uuidv4(),
        username: formData.username.trim(),
        emailaddress: formData.emailaddress,
        phone: formData.phone,
      };

      await createRestaurantstaff({
        variables: { data }
      });

      handleModalShow();
      // setFormData(INITIAL_FORM_STATE);
    }

    async function handleEditSubmit(event) {
      event.preventDefault();
      await updateRestaurantstaff({
        variables: {
          id: selectedItemId,
          data: {
            staffrole: parseInt(formData.staffrole),
            // userid: user.userID,
            // restaurant: parseInt(restaurantID),
            // rsuuid: uuidv4(),
            username: formData.username.trim(),
            emailaddress: formData.emailaddress,
            phone: formData.phone,
          },
        },
      });
      handleModalShow();

    }

    // Open Edit Items dialog / modal
    function handleShowModal(rstaff) {
      // setIsVisible(true);
      if (rstaff) {
        setSelectedItemId(rstaff.id);
        const { username, emailaddress, phone, staffrole, rsuuid } = rstaff.attributes;
        setFormData({ username, emailaddress, phone, staffrole, rsuuid });

        setModaltype("edit");
      }
      else {
        setFormData(INITIAL_FORM_STATE);
        setModaltype("add");
      }
      handleModalShow();
    }

    return (
      <Container className="bg-white ">
        <Row>
          <div className="mt-3 "> Restaurant has {tableData.length} users
            <PlusSquareFill className="h3 float-end text-primary" onClick={() => handleShowModal()} />
          </div>
        </Row>
        <Row>
          <Col>
            {
              modalshow ?
                <Col className=" rounded shadow bg-light p-3 my-4 d-flex align-items-center">

                  <button className="float-start btn-close small me-2 " onClick={() => setModalshow(false)}>  </button>
                  {/* <h5 className="text-muted">Add New Dish </h5> */}
                  {(modaltype === "edit") ?
                    <>

                      {
                        updateError &&
                        <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
                      }

                      <Form inline onSubmit={handleEditSubmit} className="d-flex mx-2">
                        <RstaffForm formData={formData} setFormData={setFormData} />
                        <Button type="submit" className="bg-primary rounded-3 shadow-none ms-3">Save </Button>
                      </Form>
                    </>
                    :
                    <>
                      {
                        createError &&
                        <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
                      }

                      <Form inline onSubmit={handleAddSubmit} className="d-flex mx-2">
                        <RstaffForm formData={formData} setFormData={setFormData} />
                        <Button type="submit" className="bg-primary rounded-3 shadow-none ms-3 w-auto">Add </Button>
                      </Form>
                    </>
                  }
                </Col>

                : <></>
            }
          </Col>
        </Row>
        <Row>

          <Table responsive>
            <thead>
              <tr>
                {tableHeader.map((header, index) => (
                  <th key={index}>
                    {header}
                  </th>
                ))}
                <th className="visually-hidden">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {tableData.map((rstaff) => (
                <tr key={rstaff.id}>
                  {/* <td></td> */}
                  <td>{rstaff.attributes.username}
                    {/* <small className="text-muted fw-light fst-italic"><br />{rstaff.attributes.rsuuid}</small> */}
                  </td>
                  <td>{rstaff.attributes.emailaddress}</td>
                  {/* <td>{dish.attributes.description.substring(0, 50)}...</td> */}
                  <td>{rstaff.attributes.phone}</td>
                  <td>
                    {(rstaff.attributes.staffrole === 1)
                      ? "Admin"
                      : "Staff"
                    }
                  </td>
                  <td>{rstaff.attributes.updatedAt}</td>

                  <td>
                    
                    {
                      user.userID === rstaff.attributes.userid ? 
                     <> <PencilSquare className="h5" onClick={() => handleShowModal(rstaff)} /> {"   "} </>
                        :
                        <>
                        <PencilSquare className="h5" onClick={() => handleShowModal(rstaff)} /> {"   "}
                        <Trash className="h5 text-danger" onClick={() => handleShowModal(rstaff.id)} />
                        </>
                    }


                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>

      </Container>
    )
  }
}

export default RestaurantUsers