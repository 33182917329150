import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Container, Row,  Button,  Modal, ModalBody } from "reactstrap";
// import { PencilSquare, ToggleOff, ToggleOn, JournalPlus, PlusSquareFill } from 'react-bootstrap-icons';
import { Spinnerx, QRCodeGen } from '.';
import { GET_RESTAURANT_DISHES_BYID } from "../utils/queries";

function RestaurantDashboard(props) {
  const restaurantID = props.restaurantID;
  const [qrcodeshow, setQrcodeshow] = useState(false);
  const handleQrcodeShow = () => setQrcodeshow(!qrcodeshow);

  const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES_BYID, {
    variables: { id: restaurantID },     
    },
  );

  // console.log(data);
  
  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;
  return (
    <Container className="bg-white vh-100">
      

    
      <Row>
        {/* <div class="col-xl-5 col-lg-6">

          <div className="row">
            <div class="col-sm-6">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-end">
                    <i class="mdi mdi-account-multiple widget-icon"></i>
                  </div>
                  <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Dishes</h5>
                  <h3 class="mt-3 mb-3">36,254</h3>
                  <p class="mb-0 text-muted">
                    <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 5.27%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-end">
                    <i class="mdi mdi-cart-plus widget-icon"></i>
                  </div>
                  <h5 class="text-muted fw-normal mt-0" title="Number of Orders">Staff Users</h5>
                  <h3 class="mt-3 mb-3">5,543</h3>
                  <p class="mb-0 text-muted">
                    <span class="text-danger me-2"><i class="mdi mdi-arrow-down-bold"></i> 1.08%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-end">
                    <i class="mdi mdi-currency-usd widget-icon"></i>
                  </div>
                  <h5 class="text-muted fw-normal mt-0" title="Average Revenue">Revenue</h5>
                  <h3 class="mt-3 mb-3">$6,254</h3>
                  <p class="mb-0 text-muted">
                    <span class="text-danger me-2"><i class="mdi mdi-arrow-down-bold"></i> 7.00%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-end">
                    <i class="mdi mdi-pulse widget-icon"></i>
                  </div>
                  <h5 class="text-muted fw-normal mt-0" title="Growth">Growth</h5>
                  <h3 class="mt-3 mb-3">+ 30.56%</h3>
                  <p class="mb-0 text-muted">
                    <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 4.87%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-xl-7 col-lg-6">
          <div class="card card-h-100">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <h4 class="header-title">Projections Vs Actuals</h4>
                <div class="dropdown">
                  <a href="#" class="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="mdi mdi-dots-vertical"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">

                    <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>

                    <a href="javascript:void(0);" class="dropdown-item">Export Report</a>

                    <a href="javascript:void(0);" class="dropdown-item">Profit</a>

                    <a href="javascript:void(0);" class="dropdown-item">Action</a>
                  </div>
                </div>
              </div>

              <div dir="ltr">
                <div id="high-performing-product" class="apex-charts" data-colors="#727cf5,#e3eaef"></div>
              </div>

            </div>
          </div>

        </div> */}
      <Button onClick={() => handleQrcodeShow()} className="mt-4 w-auto">Show QR Code</Button>
      {qrcodeshow && (
            <Modal
              centered
              fade={false}
              isOpen={qrcodeshow}
              toggle={handleQrcodeShow}
            //  toggle={function noRefCheck(){}}
            >
              {/**       <ModalHeader toggle={handleModalShow}>
              
            </ModalHeader> */}
              <ModalBody>
              
    
                  <QRCodeGen name={data.restaurant.data.attributes.name} slug={data.restaurant.data.attributes.slug} setQrcodeshow={setQrcodeshow} />
                  
              </ModalBody>


            </Modal>
          )}
      </Row>

      {/* <QRCodeGen /> */}
    </Container>
  );
}

export default RestaurantDashboard