import { createUploadLink } from 'apollo-upload-client';

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { makeVar } from '@apollo/client';
// import { ErrorHandler } from '../components';

const cache = new InMemoryCache({
  typePolicies: {
    Restaurant: {
      keyFields: ["ruuid"],
    },
    Restaurantstaff: {
      keyFields: ["rsuuid"],
    },
    Dish: {
      keyFields: ["duuid"],
    },
    Query: {
      fields: {
        cartItems: {
          read() {
            return cartItemsVar();
          }
        }
      }
    },
  },
});
const defaultOptions = { mutate: { errorPolicy: 'all' } };
const httpLink = createUploadLink({ uri: process.env.REACT_APP_API_URL + "/graphql" });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {

    const accessToken =
      operation.operationName !== 'LoginMutation' &&
        localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user')).token
        : null;

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        //  authorization: accessToken ? `${accessToken}` : '',
      },
    };
  });

  return forward(operation);
});

// GraphQL error handling section
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }) => {
        switch (extensions.code) {
          case "data-exception":
          case "validation-failed":
            // props.history.push("/errorpage"); // redirect to something-went-wrong page
            break;
            // case "invalid-jwt":
            //   // refetch the jwt
            //   const oldHeaders = operation.getContext().headers;
            //   const token = getAccessToken();
            //   operation.setContext({
            //     headers: {
            //       ...oldHeaders,
            //       authorization: `Bearer ${token}`
            //     }
            //   });
            //   // retry the request, returning the new observable
            //   return forward(operation);
            //   break;
          default:
            // default case
            console.log(extensions.code);
        }
        return "";
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // props.history.push("/errorpage");
    }
  }
);

export const client = new ApolloClient({
  cache,
  link: errorLink.concat(concat(authMiddleware, httpLink)),
  defaultOptions,
});

export const cartItemsVar = makeVar([]);
