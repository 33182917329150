import React from 'react';
import { Spinner, Container, Row } from "reactstrap";

function Spinnerx({ message }) {
  return (
    <Container >
      {/* <Row className="  align-items-center justify-content-center vh-100"> */}
      <Row className="  align-items-center justify-content-center vh-100">
        <Spinner
          color="info"
          size=""
          >
        </Spinner>
        <p className="text-center mt-3 text-dark h6 fst-italic">{message}</p>
      </Row>
    </Container>
  );
}

export default Spinnerx;
