import React from 'react';
// import {  useNavigate } from 'react-router-dom';
import { Button } from "reactstrap";
import { Google } from 'react-bootstrap-icons';



function SocialLink({ provider }) {
  // const providers = ['Google', 'Facebook', 'Instagram', 'Twitter'];
  // const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL;
  return (
    
    <>
    
{/* <Button tag={Link} to={"https://www.foodple.com/app/api/connect/google"} className="btn-primary text-white text-center my-2 rounded-3" onClick={navigate('https://www.foodple.com/app/api/connect/google')}><Google /> Google</Button> */}
<a href={`${backendUrl}/api/connect/google`} style={{ textDecoration: 'none' }}> <Button className="bg-primary border-0 text-white text-center my-1 rounded-3 w-100" ><Google /> Google</Button></a>
{/* <a href={`${backendUrl}/api/connect/facebook`} style={{ textDecoration: 'none' }}> <Button className="bg-primary border-0 text-white text-center my-1 rounded-3 w-100" ><Facebook /> Facebook</Button> </a>
<a href={`${backendUrl}/api/connect/instagram`} style={{ textDecoration: 'none' }}> <Button className="bg-primary border-0 text-white text-center my-1 rounded-3 w-100" ><Instagram /> Instagram</Button> </a>
<a href={`${backendUrl}/api/connect/twitter`} style={{ textDecoration: 'none' }}> <Button className="bg-primary border-0 text-white text-center my-1 rounded-3 w-100" ><Twitter /> Twitter</Button> </a>
     */}
   {/**  
    <a href={`http://localhost:1337/connect/${provider}`} className="link">
      <Button type="button" social={provider}>
        <${provider} />
        {provider}
      </Button>
    </a>
    */}
    </>
  );
}

export default SocialLink;