import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import { client } from './utils/apolloclient';
import UserProvider from './utils/UserContext';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

//const client = new ApolloClient({
//  uri: 'https://48p1r2roz4.sse.codesandbox.io',
//  uri: `${process.env.REACT_APP_BACKEND_URL}`,
// uri: `http://192.168.29.69:1337/graphql`,
//  cache: new InMemoryCache()
//});

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <UserProvider>
        <App />
      </UserProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


