import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Container, Col, Input, Row, Button, Label, FormGroup, Form } from "reactstrap";
// import { PencilSquare, ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_DISH } from '../utils/mutation';
import { GET_RESTAURANT_DISHES_BYID } from "../utils/queries";

const INITIAL_ERROR_STATE = {
  name: false,
  description: false,
  image: false,
  price1: false,
  isveg: false,
  isavailable: false,
  restaurant: false,
};

const INITIAL_FORM_STATE = {
  name: "",
  description: "",
  image: "",
  price1: "",
  isveg: true,
  isavailable: true,
  menuorderid: "",
  menutype: "1",
  dishcategory: "",
  spicelevel: "",
  restaurant: "",
};

function AddMenuItem({ restaurantId, categoryData, setModalshow }) {
  //    console.log(props);
  //  const {editdishId, restaurantId, setModalshow} = props;
  //  const editdishId=props.editdishId;
  //  const restaurantId=props.restaurantId;
  //  let setModalshow = props.setModalshow;
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);

  // const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES, {
  //   variables: { id: restaurantID },
  //   // variables: { restaurantID },
  // });


  const [createDish, { loading, error }] =
    useMutation(CREATE_DISH, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_DISHES_BYID,
          variables: { id: parseInt(restaurantId) },
          // },
        },
      ],
    });
  // console.log(restaurantId);

  {/** 
    function formValidation() {
      let hasError = false;
  
      if (formData.itemName.length === 0) {
        setFormError((prevState) => ({ ...prevState, itemName: true }));
        hasError = true;
      } else {
        setFormError((prevState) => ({ ...prevState, itemName: false }));
      }
  
      if (formData.itemBrief.length === 0) {
        setFormError((prevState) => ({ ...prevState, itemBrief: true }));
        hasError = true;
      } else {
        setFormError((prevState) => ({ ...prevState, itemBrief: false }));
      }
  
      if (formData.description.length < 5) {
        setFormError((prevState) => ({ ...prevState, description: true }));
        hasError = true;
      } else {
        setFormError((prevState) => ({
          ...prevState,
          description: false,
        }));
      }
  
      return hasError;
    } */}

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let data;
    let price1val = [{
      "variantName": "",
      "price": parseFloat(formData.price1) ?? "",
      "discountPrice": parseFloat(formData.price1) ?? "",
      "default": 1
    }];
    if (formData.menutype === "0") {
      data = {
        name: formData.name.trim(),
        description: formData.description,
        menuorderid: parseFloat(formData.menuorderid),
        menutype: parseInt(formData.menutype),
        price1: [{
          "variantName": "",
          "price": "",
          "discountPrice": "",
          "default": 1
        }],
        isavailable: JSON.parse(formData.isavailable),
        restaurant: parseInt(restaurantId),
        duuid: uuidv4()
      };
    }
    else {
      data = {
        name: formData.name.trim(),
        description: formData.description,
        menuorderid: parseFloat(formData.menuorderid),
        menutype: parseInt(formData.menutype),
        price1: price1val,
        isveg: JSON.parse(formData.isveg),
        isavailable: JSON.parse(formData.isavailable),
        spicelevel: parseInt(formData.spicelevel),
        // dishcategory: parseInt(formData.dishcategory),
        restaurant: parseInt(restaurantId),
        image: null,
        duuid: uuidv4()
      };
    }
    // console.log(data);
    //  if (!formValidation()) {
    await createDish({
      variables: { data }
      //  id: editdishId,
      // data: {
      //   name: formData.name,
      //   description: formData.description,
      //   price: formData.price,
      //   isveg: formData.isveg,
      //   isavailable: formData.isavailable,
      //   restaurant: restaurantId,
      // },
      //  },
    });

    setModalshow(false);
    //  }
  }

  return (
    <>
      <Container className="bg-light ">
        <Row className=" d-flex bg-dark py-1"> <Col>
          <button className="float-start btn-close small me-2 mt-1 bg-white" onClick={() => setModalshow(false)}>  </button>
          <h5 className="text-muted">Add New Dish </h5>
        </Col></Row>
        <Row className=" d-flex align-items-center justify-content-center ">
          <Col className=" rounded shadow bg-white px-4 py-3 flex ">
            {/* <button className="float-start bg-transparent btn-close small me-2" onClick={() => setModalshow(false)}>  </button>
            <h5 className="text-muted">Add New Dish Item</h5> */}
            {
              error &&
              <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
            }


            <Form inline onSubmit={handleSubmit}>
              <FormGroup check inline>
                <Input
                  name="menutype"
                  type="radio"
                  defaultChecked
                  value={1}
                  onChange={handleChange}
                // onChange={() =>
                //   setFormData({
                //     ...formData,
                //     menutype: 1
                //   })
                // }
                />
                {' '}
                <Label check>
                  Menu Item
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  name="menutype"
                  type="radio"
                  value={0}
                  onChange={handleChange}
                // onChange={() =>
                //   setFormData({
                //     ...formData,
                //     menutype: 0
                //   })
                // }
                />
                {' '}
                <Label check>
                  Menu Category
                </Label>
              </FormGroup>
              <FormGroup >
                <Label
                  for="dishName"
                // hidden
                >
                  Name
                </Label>
                <Input
                  id="dishName"
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishDescription"
                  hidden
                >
                  Email
                </Label>
                <Input
                  id="dishDescription"
                  name="description"
                  placeholder="Description"
                  type="textarea"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <div className={` ${(formData.menutype === '0') && 'd-none'}`}>
                {' '}<FormGroup>
                  <Label
                    for="dishPrice"
                    hidden
                  >
                    Email
                  </Label>
                  <Input
                    id="dishPrice"
                    name="price1"
                    placeholder="Dish Price"
                    type="number"
                    value={formData.price1}
                    onChange={handleChange}
                  // required
                  />
                </FormGroup>
                {' '}<FormGroup>
                  <Label
                    for="dishIsVeg"
                    hidden
                  >
                    Email
                  </Label>
                  <Input
                    id="dishIsVeg"
                    name="isveg"
                    placeholder="Dish Is Veg"
                    type="select"
                    value={formData.isveg}
                    onChange={handleChange}
                    required
                  >
                    <option defaultValue={true} >
                      Veg
                    </option>
                    <option value={false}>
                      Non-Veg
                    </option>
                  </Input>
                </FormGroup>

                {/* {' '}<FormGroup>
                <Label
                  for="dishCategory"
                  hidden
                >
                  Dish Cateogy
                </Label>
                <Input
                  id="dishCategory"
                  name="dishcategory"
                  placeholder="Dish Category"
                  type="select"
                  value={formData.dishcategory}
                  onChange={handleChange}
                >
                  <option value={""}>
                    Select Dish Category
                  </option>
                  {categoryData.map((dishcategory) => (
                    <option key={dishcategory.id} value={dishcategory.id}>
                      {dishcategory.attributes.name}
                    </option>
                  ))}
                </Input>
              </FormGroup> */}
                {' '}<FormGroup>
                  <Label
                    for="dishSpicelevel"
                    hidden
                  >
                    Spice Level
                  </Label>
                  <Input
                    id="dishSpicelevel"
                    name="spicelevel"
                    placeholder="Dish spice level (0-3)"
                    type="select"
                    value={formData.spicelevel}
                    onChange={handleChange}
                  >
                    <option value={""}>
                      Select Spice Level
                    </option>
                    <option value={9} >
                      No Spice
                    </option>
                    <option value={1}>
                      Low Spice
                    </option>
                    <option value={2}>
                      Medium Spice
                    </option>
                    <option value={3}>
                      High Spice
                    </option>
                  </Input>
                </FormGroup>
              </div>
              {' '}<FormGroup>
                <Label
                  for="dishIsAvailable"
                  hidden
                >
                  Is Serving
                </Label>
                <Input
                  id="dishIsAvailable"
                  name="isavailable"
                  placeholder="Dish Is Available"
                  type="select"
                  value={formData.isavailable}
                  onChange={handleChange}
                  required
                >
                  <option defaultValue={true} >
                    Serving

                  </option>
                  <option value={false}>
                    Not Serving
                  </option>
                </Input>
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishMenuorderid"
                  hidden
                >
                  Menu Order ID
                </Label>
                <Input
                  id="dishMenuorderid"
                  name="menuorderid"
                  placeholder="Menu Order Number"
                  type="number"
                  step={"any"}
                  value={formData.menuorderid}
                  onChange={handleChange}
                />
              </FormGroup>

              <Button type="submit" className="bg-primary rounded-3 shadow-none fw-bold">Add Dish</Button>
              {'   '}
              <Button onClick={() => setModalshow(false)}> Cancel </Button>
            </Form>

          </Col>
        </Row>
      </Container>



    </>
  );
}

export default AddMenuItem