import React from 'react';
// import { Link } from 'react-router-dom';
// import { useMutation, useQuery } from '@apollo/client';
// import { UPDATE_RESTAURANT } from '../utils/mutation';
// import { GET_RESTAURANT_DISHES_BYID } from "../utils/queries";
// import { useNavigate } from 'react-router';
import { Container, Col, Row } from "reactstrap";
// import { Search, PinMapFill, BoxArrowUpRight } from 'react-bootstrap-icons';
// import { Spinnerx, SocialLink } from '.';


function RestaurantSupport() {
  return (
    <Container className="bg-white min-vh-100">
                  <Row>
                    <h3 className="pt-3">Support Info</h3>
                    {/* <p className="text-muted">Fill this form with your support request. Our team will respond to your request with in 24 hours on the contact details you have entered below.</p> */}
                    <p className="text-muted">We are revamping our support portal with more features. 
                    Meanwhile please send your support requests on clicking below link via. email. Our team will respond to your request with in 24 hours.</p>
                    {/* <p>{process.env.REACT_APP_URL + "/restaurant/" +formData.slug + "-" + restaurantID} <BoxArrowUpRight className="h5 pt-1" /> </p> */}

                  </Row>
                  <Row className="py-3">
                    <Col className="col-md-8">
                      <p className="h6">
                      <a href="mailto:vihaank1205@gmail.com">Send email</a>
                          </p>
                    </Col>
                  </Row>
                </Container>
  )
}

export default RestaurantSupport