import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from "reactstrap";
// import { PencilSquare } from 'react-bootstrap-icons';
// import { USER_PROFILE } from "../utils/queries";

function TermsAndConditions() {
  const navigate = useNavigate();
  return (
    <Container className="g-0 p-0">
      <Row className=" m-2 min-vh-100 bg-white shadow">
        <Col>
          <h3 className="pt-3 ">Terms and Conditions</h3>
          <p>
            We are updating our Terms and Conditions. Please visit us again.
          </p>
          <Button className="align-center " onClick={() => navigate('/')} > Home </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default TermsAndConditions