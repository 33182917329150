import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Container, Col, Input,  Row,  Button, Label, FormGroup, Form } from "reactstrap";
// import { PencilSquare, ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import { UPDATE_DISH_AVAILABILITY } from '../utils/mutation';
import { DISH_BY_ID_QUERY, GET_RESTAURANT_DISHES_BYID } from "../utils/queries";
// import { isNullableType } from "graphql";

const INITIAL_ERROR_STATE = {
  username: false,
  emailaddress: false,
  phone: false,
  role: false,
  userid: false,
  restaurant: false,
};

const INITIAL_FORM_STATE = {
  username: "",
  emailaddress: "",
  phone: "",
  role: "",
  userid: "",
  restaurant: "",
};

function EditRstaffUser({ editdishId, restaurantId, setModalshow }) {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);

  const [updateDish, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_DISH_AVAILABILITY, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_DISHES_BYID,
          variables: { id: restaurantId },
        },
      ],
    });

  const { loading, error } = useQuery(DISH_BY_ID_QUERY, {
    variables: {
      id: editdishId,
    },
    onCompleted: async (data) => {
      const { name, description, price, isveg, isavailable, image } = data.dish.data.attributes;
      setFormData({ name, description, price, isveg, isavailable, image });
    },
  });
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // console.log(formData.image);
    //  if (!formValidation()) {
    await updateDish({
      variables: {
        id: editdishId,
        data: {
          name: formData.name.trim(),
          description: formData.description,
          price: parseInt(formData.price),
          isveg: JSON.parse(formData.isveg),
          isavailable: JSON.parse(formData.isavailable)
          //  image: formData.image ? formData.image : null
          //  image: formData.image.data?.attributes.url
        },
      },
    });

    setModalshow(false);
    //  }
  }

  return (
    <>
      <Container className="bg-light ">
        <Row className=" d-flex"> <Col>
          <button className="float-start bg-transparent btn-close small me-2 mt-1" onClick={() => setModalshow(false)}>  </button>
          <h5 className="text-muted">Edit Dish </h5>
        </Col></Row>
        <Row className=" d-flex align-items-center justify-content-center ">
          <Col className=" rounded shadow bg-white px-4 py-3 flex ">
            {
              error &&
              <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
            }


            <Form inline onSubmit={handleSubmit}>

              <FormGroup >
                <Label
                  for="dishName"
                // hidden
                >
                  Dish Name
                </Label>
                <Input
                  id="dishName"
                  name="name"
                  placeholder="Dish Name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishDescription"
                  hidden
                >
                  Email
                </Label>
                <Input
                  id="dishDescription"
                  name="description"
                  placeholder="Dish Description"
                  type="textarea"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishPrice"
                  hidden
                >
                  Email
                </Label>
                <Input
                  id="dishPrice"
                  name="price"
                  placeholder="Dish Price"
                  type="number"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishIsVeg"
                  hidden
                >
                  Email
                </Label>
                <Input
                  id="dishIsVeg"
                  name="isveg"
                  placeholder="Dish Is Veg"
                  type="select"
                  value={formData.isveg}
                  onChange={handleChange}
                  required
                >
                  <option value={true}>
                    Veg

                  </option>
                  <option value={false}>
                    Non-Veg
                  </option>
                </Input>
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishIsAvailable"
                  hidden
                >
                  Email
                </Label>
                <Input
                  id="dishIsAvailable"
                  name="isavailable"
                  placeholder="Dish Is Available"
                  type="select"
                  value={formData.isavailable}
                  onChange={handleChange}
                  required
                >
                  <option value={true}>
                    Serving

                  </option>
                  <option value={false}>
                    Not Serving
                  </option>
                </Input>
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishImage"
                  hidden
                >
                  Dish Image
                </Label>
                <Input
                  id="dishImage"
                  name="image"
                  placeholder="Dish Image"
                  type="text"
                  value={formData.image}
                  onChange={handleChange}
                  hidden
                  readOnly={true}
                />
              </FormGroup>

              <Button type="submit" className="bg-primary rounded-3 shadow-none fw-bold">Update Dish</Button>
              {'   '}
              <Button onClick={() => setModalshow(false)}> Cancel </Button>
            </Form>

          </Col>
        </Row>
      </Container>



    </>
  );
}

export default EditRstaffUser