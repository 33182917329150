import React, { useState } from 'react';
import QrReader from 'react-qr-reader'
import { Container, Col, Button, Row } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
import { NavLink} from "reactstrap";
// import { Spinnerx } from '.';
// import { ArrowDownSquareFill } from 'react-bootstrap-icons';

function QRCodeScanner() {
  const [result, setResult] = useState('Scanning...');
  const [scanstatus, setScanstatus] = useState(true);
  const navigate = useNavigate();

  function handleScan(data) {
    if (data) {

      if (data.toLowerCase().includes('foodple.com')) {
        navigate(data.substring(23), { replace: true });
      }
      else {
        setResult('Scanned non FoodPle QR Code');
        setScanstatus(false);
      }
    }
  }
  function handleError(err) {
    console.error(err)
  }

  function handleScanstatus() {
    setScanstatus(true); 
    setResult("Scanning again...");
  }

  return (
    <Container>
      <Row className=" d-flex  justify-content-center min-vh-100">
        <Col className="col-md-4 shadow bg-white px-2 py-2 mx-3 my-3 justify-content-center text-center">
          <h5 className="py-3">
            <button className="float-start bg-transparent border-0 small btn-close mb-1" onClick={() => navigate('/')}>  </button>
            Scan FoodPle QR Code
          </h5>
          <p className="h6 fw-bolder"> {result}</p>
          {
            scanstatus ?
              <QrReader
                delay={300}
                onError={(err) => handleError(err)}
                onScan={(data) => handleScan(data)}
                style={{ width: '100%' }}
              />
              : <Button className="bg-transparent text-dark my-4" onClick={() => handleScanstatus()}>Try again</Button>
          }

          

          <hr />
          <p className="text-center mx-2">If any challanges using QR code try Restaurant Search option.</p>
          <NavLink tag={Link} to={"/restaurant"} className="btn-secondary btn align-center mx-4 text-light fw-bolder">
            Find Restaurant
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}

export default QRCodeScanner;
