import React from "react";
import { Link, useNavigate } from 'react-router-dom';
// import Restaurant from './Restaurant';
// import { UserContext } from '../utils/UserContext';
import { Header, Footer } from '../components';

import { NavLink, Col, Row, Container, Card, Button } from "reactstrap";
import { Search, QrCodeScan } from 'react-bootstrap-icons';
// import { graphql } from "graphql";
// import logo from '../foodple-logo.png';

function Home() {
  // const jwt = localStorage.getItem('jwt');
  // const { user } = useContext(UserContext);
  // const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  // const [qrmodal, setQrmodal] = useState(false);
  // const Toggle = () => setQrmodal(!qrmodal);
  // console.log(process.env.REACT_APP_API_URL + "/graphql");
  const hometilestyles = "text-center my-2 py-2 align-items-center shadow-sm bg-transparent border-0 border-white rounded";
  // const logout = () => {
  //   localStorage.clear();
  //   setUser(null);
  //   navigate('/login');
  // }

  return (
    <Container className="flex min-vh-100 m-0 p-0">
      <Header />
      <Container className="bg-white">
        <Row className="position-relative d-flex  justify-content-center">

          <div className="text-center m-0 p-0 " style={{ backgroundImage: "url(images/homebg.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat", overflow: "hidden", color: "#FFFF" }}>
            <div className="p-0 m-0" style={{ background: "rgba(10, 60, 161, 0.7)", overflow: "hidden", height: "auto", paddingBottom: "50%" }}>

              <Row className="p-2">
                <Col md="6" xs="12" className="py-2 m-0 text-center">

                  <p className="h5 py-2 mt-0">
                    Digitalize restaurant operations with <strong>FoodPle</strong> to enhance customer experience, increase efficiency and boost brand visibility. </p>
                  {/* <p className="h5 border-bottom pb-1 fw-bolder">Order smartly & Operate digitally. </p> */}
                  <small >Register for free - No credit card required.</small>
                </Col>
                
              </Row>
              <hr className="m-2"/>
              <Row className=" justify-content-evenly p-2 ">
                
                  <Col xs="6">
                    <NavLink tag={Link} to={"/scanqrcode"} className="p-0" >
                      <Card className={hometilestyles}>
                        <QrCodeScan className="text-white border border-white rounded-3 p-2" style={{ width: "48px", height: "48px" }} />
                        <span className="fw-bolder text-white">Scan QR Code</span>
                        {/* <small className="lh-1">to access restaurant menu</small> */}
                      </Card>
                    </NavLink>
                  </Col>
                  <Col xs="6">
                    <NavLink tag={Link} to={"/restaurant"} className="p-0" >
                      <Card className={hometilestyles} >
                        <Search  className="text-white border border-white rounded-3 p-2" style={{ width: "48px", height: "48px" }} />
                        <span className="fw-bolder text-white">Find Restaurant</span>
                        {/* <small className="lh-1">search restaurants around</small> */}
                      </Card>
                    </NavLink>
                  </Col>
                  
              </Row>
            </div>
          </div>
        </Row>
        <Row className="py-3">
          <Col xs="12" sm="6" className="text-center">
          <h3 className="mb-0 text-dark">Join and grow your business! </h3> 
          <span className="border-bottom ">Free and without commissions. </span>
          {/* <p className="fw-bolder"><Link to={"/"}> See Demo</Link></p> */}
          <Row className="">
      
            {/* <Col>
              <p><span className="h4  rounded-3 px-2 p-1 bg-light" style={{ color: "#0a3ca1"}}><JournalPlus /></span></p>
              <small>1. Register your business</small>
            </Col>
            <Col>
              <p><span className="h4  rounded-3 px-2 p-1 bg-light " style={{ color: "#0a3ca1"}}><Sliders /></span></p>
              <small>2. Configure preferences</small>
            </Col>
            <Col>
              <p><span className="h4  rounded-3 px-2 p-1 bg-light" style={{ color: "#0a3ca1"}}><Shop /></span></p>
              <small>3. Go LIVE & Grow</small>
            </Col> */}
            <div className="py-3"><Button className="w-100 fw-bolder border-0" style={{ background: "rgba(241, 110, 97, 1)"}} onClick={ () => navigate('/register-restaurant')} >Register Your Business </Button>
            <small className="">Restaurants . Cafe . Bakery . Bar . Pub . SweetShop . JuiceShops . IceCream . Drive-In . Dhabas . PaanShop </small>
            </div>
            <span className="w-100"><img className="w-75" src={`/images/foodple-features-home-img.jpg`} alt="" /> </span>
          </Row>
          </Col>
          <Col className="text-center">
          <p className="fw-bolder"><Link to={"/restaurant/kef-donalds-a26a27f4-611a-4a11-bd6d-f3d8002c103d"}> See Demo</Link></p>
          <span className="w-100"><img className="w-75" src={`/images/appdemo.jpg`}  alt="" /> </span>
          </Col>
        </Row>
        <Row>
          
        </Row>


      </Container>
      <Footer className="align-self-flex-end position-absolute bottom-0" />
    </Container>
  );
}

export default Home;

