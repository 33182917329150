import React from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    Row,
    Col,
    Container
} from "reactstrap";

function RestaurantGroupList(props) {
    const searchQuery = props.restaurant;
    // console.log(searchQuery);
    if (searchQuery.length !== 0) {
        return (
            <Container>
                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                    {searchQuery.map((res) => (
                        <Col key={res.id}>
                            <Card >
                                {
                                    (res.attributes.image.data) ?
                                        <CardImg
                                            top={true}
                                            src={`${process.env.REACT_APP_API_URL}${res.attributes.image.data[0].attributes.url}`}
                                        />
                                        : <></>
                                }

                                <CardBody>
                                    <CardTitle className="h5">{res.attributes.name}</CardTitle>
                                    <CardText>{res.attributes.description}</CardText>
                                    <Link
                                        to={`/restaurant/${res.attributes.price1.slug}`}
                                    >
                                        <span className="btn btn-primary">Open</span>
                                    </Link>
                                </CardBody>
                                {/* <div className="card-footer">
                                    
                                </div> */}
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    } else {
        return <h1>No Restaurants Found</h1>;
    }

}

export default RestaurantGroupList;
