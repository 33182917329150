import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_RESTAURANT } from '../utils/mutation';
import { GET_RESTAURANT_DISHES_BYID } from "../utils/queries";
// import { useNavigate } from 'react-router';
import { Container, Col, Input, Form, Row, Button, Label, FormGroup, FormText } from "reactstrap";
import {  PinMapFill, BoxArrowUpRight } from 'react-bootstrap-icons';
import { Spinnerx } from '.';

const INITIAL_ERROR_STATE = {
  itemName: false,
  itemBrief: false,
  description: false,
};

const INITIAL_FORM_STATE = {
  name: "",
  slug: "",
  phone1: "",
  description: "",
  website: "",
  landmark: "",
  address: "",
  city: "",
  pincode: "",
  latitude: "",
  longitude: "",
  image: "",
  currency: "",
  fssai: "",
  gstin: "",
  facebook: "",
  twitter: "",
  instagram: "",
  isactive: "",
};

function RestaurantSettings(props) {
  const restaurantID = props.restaurantID;
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);

  const [updateRestaurant, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_RESTAURANT, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_DISHES_BYID,
          variables: { id: restaurantID },
        },
      ],
    });

  const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES_BYID, {
    variables: { id: restaurantID },
    onCompleted: async (data) => {
      // console.log(data);
      const { name, slug, phone1, description, website, landmark, address, city, pincode, latitude, longitude, image, currency, fssai, gstin, facebook, twitter, instagram, isactive } = data.restaurant.data.attributes;
      setFormData({ name, slug, phone1, description, website, landmark, address, city, pincode, latitude, longitude, image, currency, fssai, gstin, facebook, twitter, instagram, isactive });
    },
  });



  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // console.log(formData);
    //  if (!formValidation()) {
    await updateRestaurant({
      variables: {
        id: restaurantID,
        data: {
          name: formData.name.trim(),
          phone1: formData.phone1,
          description: formData.description,
          website: formData.website,
          landmark: formData.landmark,
          address: formData.address,
          city: formData.city,
          pincode: formData.pincode,
          latitude: parseFloat(formData.latitude),
          longitude: parseFloat(formData.longitude),
          // image: formData.image,
          currency: formData.currency,
          fssai: formData.fssai,
          gstin: formData.gstin,
          facebook: formData.facebook,
          twitter: formData.twitter,
          instagram: formData.instagram,
          isactive: formData.isactive
        },
      },
    });
    alert("Restaurant settings are saved.");
    // setModalshow(false);
    //  }
  }


  function loadLonLat() {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log("postion");
        setFormData({
          ...formData,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      });

    }

  }


  // const rslug = {process.env.REACT_APP_URL + "/restaurant/" +formData.slug + "-" + restaurantID};
  const rslug = process.env.REACT_APP_URL + "/restaurant/" + formData.slug;
  // console.log(rslug);
  return (
    <Container className="bg-white min-vh-100">
      <Row>
        <h3 className="pt-3">Restaurant Settings</h3>
        <p className="text-muted">Use this section to update all information/ customization related to your restaurant.</p>
        {/* <p>{process.env.REACT_APP_URL + "/restaurant/" +formData.slug + "-" + restaurantID} <BoxArrowUpRight className="h5 pt-1" /> </p> */}

      </Row>
      <Row className="py-3">
        <Col className="col-md-8">
          <Form inline onSubmit={handleSubmit}>
            <FormGroup row>
              <Label
                for="restaurantName"
                sm={2}
              >
                Name
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantName"
                  name="name"
                  placeholder="Restaurant Name"
                  type="text"
                  value={formData.name ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantUrl"
                sm={2}
              >
                URL
              </Label>
              <Col sm={10} className="">
                {/* <a href={rslug} target="_blank" rel="noreferrer noopener">{rslug}</a><BoxArrowUpRight className="h6 pt-1" /> */}
                <Button className="text-primary text-decoration-underline bg-transparent text-start border-0 m-0 p-0" onClick={() => window.open(rslug)} >{rslug}
                  <BoxArrowUpRight className="h6 pt-1" /></Button> <br />
                {/* <Input
                  id="restaurantUrl"
                  name="slug"
                  placeholder=""
                  type="text"
                  disabled
                  value={formData.slug + "-" + restaurantID}
                />
                <div className="m-1 px-1 border">
                <BoxArrowUpRight className="h1 pt-1" />
                </div> */}
                <FormText>
                  Diners will use this URL to access your restaurant.
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantPhone"
                sm={2}
              >
                Contact Number
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantPhone"
                  name="phone1"
                  placeholder="Contact Number"
                  type="text"
                  value={formData.phone1 ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantDescription"
                sm={2}
              >
                About Restaurnat
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantDescription"
                  name="description"
                  type="textarea"
                  value={formData.description ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantWebsite"
                sm={2}
              >
                Website
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantWebsite"
                  name="website"
                  placeholder="Website"
                  type="text"
                  value={formData.website ?? ""}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            {/* <hr /> */}
            <FormGroup row>
              <Label
                for="restaurantLandmark"
                sm={2}
              >
                Landmark
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantLandmark"
                  name="landmark"
                  placeholder="Landmark Information"
                  type="text"
                  value={formData.landmark ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantAddress"
                sm={2}
              >
                Address
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantAddress"
                  name="address"
                  type="textarea"
                  value={formData.address ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantCity"
                sm={2}
              >
                City
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantCity"
                  name="city"
                  placeholder="City Name"
                  type="text"
                  value={formData.city ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantPincode"
                sm={2}
              >
                Pincode
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantPincode"
                  name="pincode"
                  placeholder="Pincode"
                  type="text"
                  value={formData.pincode ?? ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantCoordinates"
                sm={2}
              >
                Google Map Coordinates
              </Label>
              <Col sm={10} className="d-flex">
                <Input
                  id="restaurantCoordinates"
                  name="latitude"
                  placeholder="Latitude"
                  type="number"
                  value={formData.latitude ?? ""}
                  onChange={handleChange}
                  required
                />
                <Input
                  id="restaurantCoordinates"
                  name="longitude"
                  placeholder="Longitude"
                  type="number"
                  value={formData.longitude ?? ""}
                  onChange={handleChange}
                  required
                />
                <div className="m-1 px-1 border">
                  <PinMapFill className="h1 pt-1" onClick={() => loadLonLat()} />
                </div>
              </Col>
              <FormText>
                Click on Map icon to capture your current coordinates.
              </FormText>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantImage"
                sm={2}
              >
                Image
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantImage"
                  name="image"
                  type="file"
                  value={formData.image}
                  onChange={handleChange}
                  disabled
                />
                <FormText>
                  Restaurant image file to show. Temporarily unavailable
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantCurrency"
                sm={2}
              >
                Currency
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantCurrency"
                  name="currency"
                  placeholder="Currency"
                  type="text"
                  value={formData.currency ?? ""}
                  onChange={handleChange}
                  required
                />
                <FormText>
                  Dish cost will show in this currency symbol. Only enter 3 chars Currency code or Symbol.
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantLicense"
                sm={2}
              >
                License#
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantLicense"
                  name="fssai"
                  placeholder="License Information"
                  type="text"
                  value={formData.fssai ?? ""}
                  onChange={handleChange}
                />
                <FormText>
                  Food, restaurant license number display will increase the authenticity of restaurant.
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantTaxno"
                sm={2}
              >
                Tax #
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantTaxno"
                  name="gstin"
                  placeholder="Tax Number"
                  type="text"
                  value={formData.gstin ?? ""}
                  onChange={handleChange}
                />
                <FormText>
                  Tax numbers like VAT, GST etc which needs to be printed in invoices.
                </FormText>
              </Col>
            </FormGroup>
            {/* Social Links Code */}
            <FormGroup row>
              <Label
                for="restaurantFacebook"
                sm={2}
              >
                Facebook
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantFacebook"
                  name="facebook"
                  placeholder="facebook URL"
                  type="text"
                  value={formData.facebook ?? ""}
                  onChange={handleChange}
                />
                <FormText>
                  Enter complete URL including https://
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantTwitter"
                sm={2}
              >
                Twitter
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantTwitter"
                  name="twitter"
                  placeholder="twitter URL"
                  type="text"
                  value={formData.twitter ?? ""}
                  onChange={handleChange}
                />
                <FormText>
                  Enter complete URL including https://
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                for="restaurantInstagram"
                sm={2}
              >
                Instagram
              </Label>
              <Col sm={10}>
                <Input
                  id="restaurantInstagram"
                  name="instagram"
                  placeholder="Instagram URL"
                  type="text"
                  value={formData.instagram ?? ""}
                  onChange={handleChange}
                />
                <FormText>
                  Enter complete URL including https://
                </FormText>
              </Col>
            </FormGroup>


            {/* <FormGroup row>
              <Label
                for="exampleSelect"
                sm={2}
              >
                Select
              </Label>
              <Col sm={10}>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                >
                  <option>
                    1
                  </option>
                  <option>
                    2
                  </option>
                  <option>
                    3
                  </option>
                  <option>
                    4
                  </option>
                  <option>
                    5
                  </option>
                </Input>
              </Col>
            </FormGroup> */}

            <FormGroup row className="bg-light py-3 border-top border-dark ">
              <Label
                for="isactive"
                sm={2}
              >

              </Label>
              <Col
                sm={{
                  size: 10
                }}
              >
                <FormGroup check>
                  <Input
                    id="isactive"
                    type="checkbox"
                    checked={formData.isactive}
                    // value={formData.isactive}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        isactive: !formData.isactive
                      })
                    }
                  // onChange={(e) => checked: {formData.isactive}

                  //   {
                  //   setFormData({
                  //     ...formData,
                  //     isactive: !formData.isactive
                  //   });
                  //   console.log(formData.isactive);
                  // }
                  // }
                  />
                  {/* {console.log(formData.isactive)} */}
                  {' '}
                  <Label check>
                    Enable my restaurant
                  </Label>
                </FormGroup>
                <FormText>
                  By clicking above check mark to enable, I agree all the information mentioned here is appropriate as per the local and industry regulations. <br />Customers will not be able to use your services untill it is enabled.
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup
              check
              row
            >
              <Col
                sm={{
                  offset: 2,
                  size: 10
                }}
              >
                <Button className="bg-primary">
                  Save Changes
                </Button>
                {/* <Button className="bg-secondary ms-3">
                  Reset
                </Button> */}
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default RestaurantSettings