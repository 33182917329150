import { gql } from "@apollo/client";

// User profile query
export const USER_PROFILE = gql`
  query UserProfile {
    me {
      id
      username
      email
      
    }
  }
`;

// Used by frontend to query list of active restaurants
export const RESTAURANTS_LIST = gql`
  query GetRestaurantsList {
    restaurants (filters: {isactive: {eq: true} }) {
      data {
        id
        attributes {
          name
          ruuid
          description
          landmark
          city
          slug
          isactive
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

// Used by customer frontend to query info based on RUUID Field
export const GET_RESTAURANT_DISHES = gql`
  query GetRestaurantDishes($slug: String!) {
    restaurants(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          name
          currency
          description
          city
          website
          isactive
          landmark
          address
          pincode
          latitude
          longitude
          phone1
          restauranttype
          fssai
          gstin
          slug
          ruuid
          facebook
          twitter
          instagram
          dishcategories {
            data {
              attributes {
                name
              }
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
          dishes(sort: ["menuorderid"]) {
            data {
              id
              attributes {
                name
                description
                price
                price1
                isveg
                isavailable
                menuorderid
                spicelevel
                updatedAt
                duuid
                menutype
                dishcategory {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Used by Admin Portal queries based on the Restaurant inbuilt ID field
export const GET_RESTAURANT_DISHES_BYID = gql`
  query GetRestaurantDishesID($id: ID!) {
    restaurant(id: $id) {
      data {
        id
        attributes {
          name
          currency
          description
          city
          website
          isactive
          landmark
          address
          pincode
          latitude
          longitude
          phone1
          fssai
          restauranttype
          gstin
          ruuid
          facebook
          twitter
          instagram
          slug
          dishcategories {
            data {
              id
              attributes {
                name
              }
            }
          }
          dishes(sort: ["menuorderid"]) {
            data {
              id
              attributes {
                name
                description
                price
                price1
                isveg
                isavailable
                menuorderid
                spicelevel
                duuid
                menutype
                updatedAt
                dishcategory {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DISH_BY_ID_QUERY = gql`
  query GetDishById($id: ID!) {
    dish(id: $id) {
      data {
        id
        attributes {
          name
          description
          price
          price1
          isveg
          isavailable
          menuorderid
          spicelevel
          duuid
          menutype
          updatedAt
          dishcategory {
            data {
              id
              attributes {
                name
              }
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

//Used by Restaurant Vendor component for myrestaurant page
export const GET_RESTAURANT_LIST_BY_USER_ID_QUERY = gql`
  query GetRestaurantStaff($userid: String!) {
    restaurantstaffs(filters: {
      userid: {
        eq: $userid
      }
    }) {
      data {
        id 
        attributes {
          userid
          rsuuid
          staffrole
          restaurant {
            data {
              id
              attributes {
                name
                city
                ruuid
              }
            }
          }
        }
      }
    }
  }
  `;

// Used by restorant portal - users section
export const GET_RESTAURANT_USERS_BY_RID_QUERY = gql`
  query GetRestaurantStaff($id: ID!) {
    restaurantstaffs(filters: { restaurant: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          rsuuid
          username
          emailaddress
          phone
          staffrole
          updatedAt
          userid
        }
      }
    }
  }
`;

export const GET_RESTAURANT_LIST_BY_STAFF_ID_QUERY = gql`
  query GetRestaurantDishesByStaffID($userid: String!) {
    restaurants(filters: { restaurantstaffs: { userid: { eq: $userid } } }) {
      data {
        id
        attributes {
          name
          currency
          restaurantstaffs {
            data {
              attributes {
                userid
                staffrole
              }
            }
          }
          dishes {
            data {
              id
              attributes {
                name
                description
                price
                isveg
                isavailable
                updatedAt
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;

export const EXCHANGE_RATES = gql`
  query GetExchangeRates {
    rates(currency: "USD") {
      currency
      rate
    }
  }
`;

export const ALL_REVIEWS = gql`
  query  GetAllReviews {
    reviews {
      data {
          id
        attributes {
          title
          rating
          description
          updatedAt
        }
      }
    }
  }
`;

export const REVIEW = gql`
  query  GetReviewById($id: ID!) {
    review(id:$id) {
      data {
          id
        attributes {
          title
          rating
          description
          updatedAt
        }
      }
    }
  }
`;
