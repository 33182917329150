import React from 'react';
import { useQuery } from "@apollo/client";
import { Link } from 'react-router-dom';
//import { RestaurantMenu } from './components';
import { RESTAURANTS_LIST } from "../utils/queries";
import { Spinnerx } from '.';
import {
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    Row,
    Col,
    Container
} from "reactstrap";

function RestaurantList(props) {


    const { loading, error, data } = useQuery(RESTAURANTS_LIST);
    if (error) return "Error loading restaurants";
    //if restaurants are returned from the GraphQL query, run the filter query
    //and set equal to variable restaurantSearch
    if (loading) return <Spinnerx message="Fetching..." />;


    // removed code from here 
    if (data.restaurants.data && data.restaurants.data.length) {
        //searchQuery
        const searchQuery = data.restaurants.data.filter((restaurant) =>
            restaurant.attributes.name.toLowerCase().includes(props.search)
            // (restaurant.attributes.name.toLowerCase().includes(props.search)) && (restaurant.attributes.isactive === true)
        );
        if (searchQuery.length !== 0) {
            return (
                <Container>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                        {searchQuery.map((res) => (
                            <Col key={res.id}>
                                <Card >
                                    {
                                        (res.attributes.image.data.length > 0) ?
                                            <CardImg
                                                top={true}
                                                src={`${process.env.REACT_APP_API_URL}${res.attributes.image.data[0].attributes.url}`}
                                            />
                                            : <></>
                                    }

                                    <CardBody>
                                        <CardTitle className="h5">{res.attributes.name}</CardTitle>
                                        <CardText>{res.attributes.landmark}</CardText>
                                    </CardBody>
                                    <div className="card-footer">
                                        <Link
                                            to={`/restaurant/${res.attributes.slug}`}
                                        >
                                            <p className="btn btn-primary">View</p>
                                        </Link>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            );
        } else {
            return <h1>No Restaurants Found</h1>;
        }
    }
    // to here
    return <h5>Add Restaurants</h5>;
}

export default RestaurantList;
