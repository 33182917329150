import React, {useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
// import {  } from "reactstrap";

import Home from './containers/Home';
import Restaurant from './containers/Restaurant';
import RestaurantVendor from './containers/RestaurantVendor';
// import PrivateRoute from './utils/PrivateRoute';
import { ErrorHandler, Signup, RestaurantPage, Login, UserProfile, NotFound404, QRCodeGen, QRCodeScanner, IconsList, AddRestaurant, LoginRedirect, FeaturesPortal, PrivacyPolicy, TermsAndConditions } from './components';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLEGA4_TRACKING_CODE);

const App = () => {
  // const GA_TRACKING_ID = "G-69TBD266W7"; // G-69TBD266W7
  // ReactGA.initialize(GA_TRACKING_ID);
  const location = useLocation();
  // ReactGA.send({ hitType: "pageview", page: location.pathname });
  useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  //   // ReactGA.send("pageview");
  //   console.log(location.pathname);
  }, [location]);
  
  return (
    <div className="container m-0 p-0 ">
      {/* <div className="container-fluid  min-vh-100 m-0 p-0 pb-2"> */}
      {/* <Header /> */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/myaccount" element={<UserProfile />} />
        <Route exact path="/connect/:providerName/redirect" element={<LoginRedirect />} />
        <Route path="/scanqrcode" element={<QRCodeScanner />} />
        <Route path="/scanqrgen" element={<QRCodeGen />} />
        {/* <Route path="/iconslist" element={<IconsList />} /> */}
        <Route path="/restaurant" element={<Restaurant />} />
        <Route path="/myrestaurant" element={<RestaurantVendor />} />
        <Route path="/register-restaurant" element={<AddRestaurant />} />
        <Route path="/feature-request" element={<FeaturesPortal />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        {/* <Route path="/restaurant/:slug-:id" element={<RestaurantPage />} /> */}
        <Route path="/restaurant/:slug" element={<RestaurantPage />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/errorpage" element={<ErrorHandler />} />
        <Route exact path="*" element={<NotFound404 />} />
      </Routes>
      {/* </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default App;
