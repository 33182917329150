import React from 'react';
import QRCode from "react-qr-code";
import { Col, Row, Container, Card, CardImgOverlay } from "reactstrap";
import qrcodelogo from '../QRcodeLogo.png';
// import { useNavigate, useLocation } from 'react-router';

function QRCodeGen({ name, slug, setQrcodeshow }) {

  const urlslug = (window.location.origin + "/restaurant/" + slug) || "www.foodple.com";

  return (
    <Container>
      <Row>
        <button className="float-start btn-close small me-2 mt-1 bg-white" onClick={() => setQrcodeshow(false)}>  </button>
      </Row>
      <Row className=" d-flex align-items-center justify-content-center g-0">
        <Col className="rounded shadow-lg bg-white px-3 py-3  mx-3 my-3">
          <h2 className="text-center fw-bolder">{name}</h2>
          <hr />

          <Card inverse className="border-0 text-center">
            <div className="align-items-middle justify-content-center">
              <QRCode value={urlslug} title={name} level='M' size={256} className="text-center" />
            </div>
            <CardImgOverlay className="align-items-center justify-content-center" >
              {/* <div className="align-items-center justify-content-center" style={{ height: '256' }}> */}
              <img src={qrcodelogo} className="h-25 w-25 align-middle mt-5" alt="FoodPle" />
              {/* <br /> <br /> <br /> <br /> <br /> <br /> 
            <span className="align-middle text-center h4 bg-white text-primary fw-bolder p-2 rounded-3 " >www.foodple.com</span> */}

              {/* </div> */}
            </CardImgOverlay>
          </Card>
          <p className="text-center text-dark fw-bolder mb-1 h5">www.foodple.com</p>
          <hr className="mt-1" />
          <p className="text-center h5">scan me to access Menu </p>
        </Col>
      </Row>
    </Container>
  );
}

export default QRCodeGen;
