import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Container, Col, Input, Row, Button, Label, FormGroup, Form } from "reactstrap";
// import { PencilSquare, ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import { UPDATE_DISH_AVAILABILITY } from '../utils/mutation';
import { DISH_BY_ID_QUERY, GET_RESTAURANT_DISHES_BYID } from "../utils/queries";
// import { isNullableType } from "graphql";

const INITIAL_ERROR_STATE = {
  itemName: false,
  itemBrief: false,
  description: false,
};

const INITIAL_FORM_STATE = {
  name: "",
  description: "",
  image: "",
  price1: "", 
  dprice1: "",
  isveg: "",
  isavailable: "",
  menuorderid: "",
  menutype: "",
  spicelevel: "",
  restaurant: "",
};

function EditMenuItem({ editdishId, restaurantId, categoryData, setModalshow }) {
  //    console.log(props);
  //  const {editdishId, restaurantId, setModalshow} = props;
  //  const editdishId=props.editdishId;
  //  const restaurantId=props.restaurantId;
  //  let setModalshow = props.setModalshow;
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  // const [formError, setFormError] = useState(INITIAL_ERROR_STATE);

  const [updateDish, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_DISH_AVAILABILITY, {
      refetchQueries: [
        {
          query: GET_RESTAURANT_DISHES_BYID,
          variables: { id: restaurantId },
        },
      ],
    });

  const { loading, error } = useQuery(DISH_BY_ID_QUERY, {
    variables: {
      id: editdishId,
    },
    onCompleted: async (data) => {
      const { name, description, price1, isveg, isavailable, menuorderid, menutype, spicelevel, image } = data.dish.data.attributes;
      if (parseInt(menutype) === 1)
      {
        setFormData({ name, description, price1:price1[0]?.price ?? "", dprice1:price1[0]?.discountPrice ?? "", isveg, isavailable, menuorderid, menutype, spicelevel, image });
      }
      else {
        setFormData({ name, description, isveg, isavailable, menuorderid, menutype, spicelevel, image });
      }
    },
  });
  // console.log(formData);
  
  {/** 
    function formValidation() {
      let hasError = false;
  
      if (formData.itemName.length === 0) {
        setFormError((prevState) => ({ ...prevState, itemName: true }));
        hasError = true;
      } else {
        setFormError((prevState) => ({ ...prevState, itemName: false }));
      }
  
      if (formData.itemBrief.length === 0) {
        setFormError((prevState) => ({ ...prevState, itemBrief: true }));
        hasError = true;
      } else {
        setFormError((prevState) => ({ ...prevState, itemBrief: false }));
      }
  
      if (formData.description.length < 5) {
        setFormError((prevState) => ({ ...prevState, description: true }));
        hasError = true;
      } else {
        setFormError((prevState) => ({
          ...prevState,
          description: false,
        }));
      }
  
      return hasError;
    } */}

  const menutypeval = (val) => {
    // console.log(val, formData.menutype);
    if (formData.menutype === val) {  return true; }
    else { return false;}
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // console.log(formData.image);
    //  if (!formValidation()) {

    // const dcategory = formData.dishcategory.data?.id ? formData.dishcategory.data?.id : formData.dishcategory;
    let data;
    let dpriceval = formData.dprice1 ? formData.dprice1 : formData.price1;
    let price1val = [{
      "variantName": "",
      "price": parseFloat(formData.price1) ?? "",
      "discountPrice": parseFloat(dpriceval) ?? "",
      "default": 1
    }];
    if (formData.menutype === "0") {
      data = {
        name: formData.name.trim(),
        description: formData.description,
        menuorderid: parseFloat(formData.menuorderid),
        menutype: parseInt(formData.menutype),
        price1: [{
          "variantName": "",
          "price": "",
          "discountPrice": "",
          "default": 1
        }],
        isavailable: JSON.parse(formData.isavailable)
      };
    }
    else {
      data = {
        name: formData.name.trim(),
        description: formData.description,
        menuorderid: parseFloat(formData.menuorderid),
        menutype: parseInt(formData.menutype),
        price1: price1val,
        isveg: JSON.parse(formData.isveg),
        isavailable: JSON.parse(formData.isavailable),
        spicelevel: parseInt(formData.spicelevel),
        // image: null
      };
    }

    await updateDish({
      variables: {
        id: editdishId, data,
        // data: {
        //   name: formData.name.trim(),
        //   description: formData.description,
        //   price: parseFloat(formData.price),
        //   isveg: JSON.parse(formData.isveg),
        //   isavailable: JSON.parse(formData.isavailable),
        //   menuorderid: parseFloat(formData.menuorderid),
        //   spicelevel: parseInt(formData.spicelevel),
        //   // dishcategory: parseInt(dcategory)
        //   //  image: formData.image ? formData.image : null
        //   //  image: formData.image.data?.attributes.url
        // },
      },
    });

    setModalshow(false);
    //  }
  }
  // console.log(categoryData);

  return (
    <>
      <Container className="bg-light ">
        <Row className=" d-flex"> <Col>
          <button className="float-start bg-transparent btn-close small me-2 mt-1" onClick={() => setModalshow(false)}>  </button>
          <h5 className="text-muted">Edit Dish </h5>
        </Col></Row>
        <Row className=" d-flex align-items-center justify-content-center ">
          <Col className=" rounded shadow bg-white px-4 py-3 flex ">
            {
              error &&
              <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
            }


            <Form inline onSubmit={handleSubmit}>
              <FormGroup check inline>
                <Input
                  name="menutype"
                  type="radio"
                  // checked={menutypeval("1")}
                  
                  defaultChecked={ parseInt(formData.menutype) === 1 ? true : false}
                  value={1}
                  onChange={handleChange}
                />
                {' '}
                <Label check>
                  Menu Item
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  name="menutype"
                  type="radio"
                  value={0}
                  defaultChecked={ parseInt(formData.menutype) === 0 ? true : false }
                  // checked={true}
                  onChange={handleChange}
                />
                {' '}
                <Label check>
                  Menu Category
                </Label>
              </FormGroup>
              <FormGroup >
                <Label
                  for="dishName"
                // hidden
                >
                  Dish Name
                </Label>
                <Input
                  id="dishName"
                  name="name"
                  placeholder="Dish Name"
                  type="text"
                  value={formData.name ?? ""}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishDescription"
                  hidden
                >
                  Dish Description
                </Label>
                <Input
                  id="dishDescription"
                  name="description"
                  placeholder="Dish Description"
                  type="textarea"
                  value={formData.description ?? ""}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <div className={` ${(parseInt(formData.menutype) === 0) && 'd-none'}`}>
                {' '}<FormGroup>
                  <Label
                    for="dishPrice"
                    hidden
                  >
                    Dish Price
                  </Label>
                  <Input
                    id="dishPrice"
                    name="price1"
                    placeholder="Dish Price"
                    type="number"
                    value={formData.price1 ?? ""}
                    onChange={handleChange}
                    // required
                  />
                  <Input
                    id="dishPrice1"
                    name="dprice1"
                    placeholder="Discount Price"
                    type="number"
                    value={formData.dprice1 ?? ""}
                    onChange={handleChange}
                    // required
                  />
                </FormGroup>
                {' '}<FormGroup>
                  <Label
                    for="dishIsVeg"
                    hidden
                  >
                    Is Veg
                  </Label>
                  <Input
                    id="dishIsVeg"
                    name="isveg"
                    placeholder="Dish Is Veg"
                    type="select"
                    value={formData.isveg ?? ""}
                    onChange={handleChange}
                    required
                  >
                    <option value={true}>
                      Veg

                    </option>
                    <option value={false}>
                      Non-Veg
                    </option>
                  </Input>
                </FormGroup>

                {' '}<FormGroup>
                  <Label
                    for="dishImage"
                    hidden
                  >
                    Dish Image
                  </Label>
                  <Input
                    id="dishImage"
                    name="image"
                    placeholder="Dish Image"
                    type="text"
                    value={formData.image}
                    onChange={handleChange}
                    hidden
                    readOnly={true}
                  />
                </FormGroup>
                {/* {' '}<FormGroup>
                <Label
                  for="dishCategory"
                  hidden
                >
                  Dish Category
                </Label>
                <Input
                  id="dishCategory"
                  name="dishcategory"
                  placeholder="Dish Category"
                  type="select"
                  value={formData.dishcategory.data?.id }
                  onChange={handleChange}
                >
                  <option value={""}>
                    Select Dish Category
                  </option>
                  {categoryData.map((dishcategory) => (
                    <option key={dishcategory.id} value={dishcategory.id}>
                      {dishcategory.attributes.name}
                    </option>
                  ))}
                </Input>
              </FormGroup> */}
                {' '}<FormGroup>
                  <Label
                    for="dishSpicelevel"
                    hidden
                  >
                    Spice Level
                  </Label>
                  <Input
                    id="dishSpicelevel"
                    name="spicelevel"
                    placeholder="Dish spice level (0-3)"
                    type="select"
                    value={formData.spicelevel ?? ""}
                    onChange={handleChange}
                  >
                    <option value={""}>
                      Select Spice Level
                    </option>
                    <option value={9} >
                      No Spice
                    </option>
                    <option value={1}>
                      Low Spice
                    </option>
                    <option value={2}>
                      Medium Spice
                    </option>
                    <option value={3}>
                      High Spice
                    </option>
                  </Input>
                </FormGroup>
              </div>
              {' '}<FormGroup>
                <Label
                  for="dishIsAvailable"
                  hidden
                >
                  Dish Available
                </Label>
                <Input
                  id="dishIsAvailable"
                  name="isavailable"
                  placeholder="Dish Is Available"
                  type="select"
                  value={formData.isavailable ?? ""}
                  onChange={handleChange}
                  required
                >
                  <option value={true}>
                    Serving

                  </option>
                  <option value={false}>
                    Not Serving
                  </option>
                </Input>
              </FormGroup>
              {' '}<FormGroup>
                <Label
                  for="dishMenuorderid"
                  hidden
                >
                  Menu Order ID
                </Label>
                <Input
                  id="dishMenuorderid"
                  name="menuorderid"
                  placeholder="Menu Order Number"
                  type="number"
                  step={"any"}
                  value={formData.menuorderid ?? ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button type="submit" className="bg-primary rounded-3 shadow-none fw-bold">Update Dish</Button>
              {'   '}
              <Button onClick={() => setModalshow(false)}> Cancel </Button>
            </Form>

          </Col>
        </Row>
      </Container>


    </>
  );
}

export default EditMenuItem