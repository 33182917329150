import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, NavbarBrand, Collapse, NavbarToggler, Nav, NavItem, NavLink, Container } from 'reactstrap';
import { UserContext } from '../utils/UserContext';
import logo from '../foodple-logo.png';

function Header(props) {
  let title = props.title || "FoodPle";
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  // Logout functionality
  const logout = () => {
    localStorage.clear();
    setUser(null);
    navigate('/login');
  }


  return (
    <Container className="m-0 p-0 ">
      <Navbar
        //  color="dark"
        // dark
        //  expand="md"
        // full 
        className="navbar-light bg-white  navbar-light sticky-top"
      >
        <NavbarBrand tag={Link} to={"/"} className="me-auto">
        <img src={logo} className="App-logo" alt={title} />
          {/* <img src={process.env.REACT_APP_URL + "/images/foodple-logo.png"} /> */}
          <small>beta</small>
          {/* {title} */}
        </NavbarBrand>
        <NavbarToggler onClick={toggleTopbar} className="" />
        <Collapse isOpen={topbarIsOpen} onClick={toggleTopbar} navbar>
          <Nav

            navbar
          >

            {
              user ?
                <>
                  <NavItem>
                    <NavLink tag={Link} to={"/myaccount"} >
                      My Account
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to={"/myrestaurant"}>
                      Restaurant Admin Portal
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to={"/login"}>
                      <span onClick={logout}>Logout</span>
                    </NavLink>
                  </NavItem>
                </>
                :
                <NavItem>
                  <NavLink tag={Link} to={"/login"}>
                    Login
                  </NavLink>
                </NavItem>

            }
            <hr className="my-1" />
            <NavItem>
              <NavLink tag={Link} to={"/"} >
                Home
              </NavLink>
              </NavItem>
            <NavItem>
              <NavLink tag={Link} to={"/scanqrcode"} >
                Scan Menu QR Code
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={"/restaurant"}>
                Search Restaurant
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={"/register-restaurant"}>
                Register Restaurant
              </NavLink>
            </NavItem>
            <hr className="my-1" />
            <NavItem>
              <NavLink tag={Link} to={"/feature-request"}>
                Feedback / Feature Request
              </NavLink>
            </NavItem>
          </Nav>

          {/* <HeaderUserbox /> */}
        </Collapse>

      </Navbar>
    </Container>
  )
}

export default Header