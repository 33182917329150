import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../utils/mutation';
import { useNavigate, useLocation } from 'react-router';
import { UserContext } from '../utils/UserContext';
import { Container, Row, Col, Button, NavLink, FormGroup, Form, Label, Input } from "reactstrap";
// import {  Eye } from 'react-bootstrap-icons';
import { Spinnerx, SocialLink } from '.';
import Header from './Header';


const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const { user, setUser } = useContext(UserContext);
  let location = useLocation();
  // console.log(location);
  // console.log(location.state.returnpath);
  useEffect(() => {
    if(user) {
      navigate('/myaccount');
  }
  }, [user, navigate]);

  const [loginUser, { loading, error, data }] = useMutation(LOGIN_USER)
  //const jwt = localStorage.getItem('jwt'); 

  if (loading) return <Spinnerx message="Logging in..." />
  if (data) {
    // console.log(data);
      const { login } = data;
      setUser({ token: login.jwt, userID: login.user.id });
   // localStorage.setItem("jwt", data.login.jwt)
   if(location.state?.returnpath)
   { 
     navigate(location.state.returnpath);
    
   } else
   {
     navigate("/");
   }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    loginUser({
      variables: {
        input: formData
      }
    })
  }
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  return (
    <Container className="m-0 p-0">
      <Header />

    

    <Container className="bg-light min-vh-100">
          <Row className=" d-flex align-items-center justify-content-center ">
            <Col className="col-md-4 rounded shadow bg-white px-4 py-3 flex mx-3 my-3">
              <button className="float-start bg-transparent btn-close small me-2" onClick={() => navigate('/')}>  </button>
              <h4 className="text-muted" >Login </h4>
              <nav>
                <SocialLink />
                <Row className="justify-content-center g-0"><Col className="col-5"><hr /></Col>
                  <Col className="col-1"><p className="text-center mb-1">Or</p></Col> <Col className="col-5"><hr /></Col></Row>
              </nav>
              {
                error &&
                <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
              }
              <Form inline onSubmit={handleSubmit}>
                <FormGroup>
                  <Label
                    for="exampleEmail"
                    hidden
                  >
                    Email
                  </Label>
                  <Input
                    id="exampleEmail"
                    name="identifier"
                    placeholder="Email / Username"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {' '}
                <FormGroup>
                  <Label
                    for="examplePassword"
                    hidden
                  >
                    Password
                  </Label>
                  {/** Need to implement show password functionality */}
                  <Input
                    id="examplePassword"
                    name="password"
                    placeholder="Password"
                    type="password"
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup check>
                  <Input
                    id="checkbox2"
                    type="checkbox"
                  />
                  {' '}
                  <Label check>
                    Remember me
                  </Label>
                </FormGroup>
                {' '}
                <Button type="submit" className="bg-primary rounded-3 w-100 shadow-none border-0">Login</Button>
              </Form>
              <hr />
              <NavLink tag={Link} to={"/signup"} className="float-start text-muted small" > Forgot Pasword?</NavLink>
              <NavLink tag={Link} to={"/signup"} className="float-end text-muted small" > New User? <b>SignUp</b></NavLink>
            </Col>
          </Row>
      
    </Container>
    </Container>
  )
}

export default Login