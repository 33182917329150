import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Nav, NavItem, NavLink, NavbarBrand } from "reactstrap";


function Footer() {
    return (
        <Container className="m-0 p-0 py-1 border-top px-4 bg-light ">
            <Row>
                <Nav>
                    <Col sm="6" xs="12" md="3">
                        <NavbarBrand tag={Link} to={"/"} className="me-auto">
                            FoodPle
                        </NavbarBrand>
                        <p className="mt-2">A platform to build synergies in food & beverages industry processes.</p>
                    </Col>

                    <Col sm="6" xs="12" md="2">
                        <p className="text-muted h6"><small>COMPANY</small></p>
                        <NavItem>
                            <NavLink tag={Link} to={"/"} >
                                Who we are
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={"/"} >
                                Report Fraud
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={"/"} >
                                Contact
                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col sm="6" xs="12" md="2">
                        <p className="text-muted h6"><small>FOR FOODIES & RESTAURANTS</small></p>
                        <NavItem>
                            <NavLink tag={Link} to={"/"} >
                                Help & Support
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={"/scanqrcode"} >
                                Scan Menu QR Code
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={"/register-restaurant"}>
                                Register Restaurant
                            </NavLink>
                        </NavItem>

                    </Col>
                    <Col sm="6" xs="12" md="2">
                        <p className="text-muted h6 mb-4"></p>
                        <NavItem>
                            <NavLink tag={Link} to={"/privacy-policy"} >
                                Privacy
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={"/terms-and-conditions"} >
                                Terms of Service
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} to={"/"} >
                                Security
                            </NavLink>
                        </NavItem>
                    </Col>
                </Nav>
            </Row>

        </Container>
        // <></>
    );
}

export default Footer