import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { UserContext } from '../utils/UserContext';
import { NotFound404, Spinnerx, RestaurantMenu, RestaurantGroupList } from '.';
import { Container, Modal, Card, ModalBody, Col, Row, Nav, NavItem, NavLink, Navbar, NavbarToggler, Button, Offcanvas, OffcanvasHeader, OffcanvasBody, InputGroup, InputGroupText, Input, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { GET_RESTAURANT_DISHES } from "../utils/queries";
import { HouseDoor, ArrowRepeat, ArrowBarLeft, HouseFill, Bookmark, Share, Map, Files, Search, XLg, CardHeading, Telephone, Globe, PinMap, DashLg, PlusLg, Trash } from 'react-bootstrap-icons';
// import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import { HashLink } from 'react-router-hash-link';
import { cartItemsVar } from '../utils/apolloclient';
import { useReactiveVar } from '@apollo/client';

function RestaurantPage(props) {
  const [showtab, setShowtab] = useState("0");
  const navigate = useNavigate();
  let { slug } = useParams();
  // const location = useLocation();
  // console.log(location.pathname);
  // let ruuid = slug.substring((slug.length - 36));
  const [orderitems, setOrderitems] = useState([]);
  const { setUser } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [query, updateQuery] = useState("");
  const [catmenushow, setCatmenushow] = useState(false);
  const handleCatmenushow = () => setCatmenushow(!catmenushow);
  const [cartshow, setCartshow] = useState(false);
  const handleCartshow = () => setCartshow(!cartshow);
  const [showlogo, setShowlogo] = useState(false);
  const cartItems = useReactiveVar(cartItemsVar);
  const [titlepadding, setTitlepadding] = useState("90px");
  const [lastScrollY, setLastScrollY] = useState(0);
  // Logo visibiity functionality
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      // if (window.scrollY > lastScrollY) { // if scroll down hide the logo
      if (window.scrollY < 110) { // if scroll down hide the logo
        setShowlogo(false);
        setTitlepadding("90px");
      } else { // if scroll up show the logo
        setShowlogo(true);
        setTitlepadding("9px");
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  // ReactGA.send({ hitType: "pageview", page: location.pathname });
  // ReactGA.event({
  //   category: "Restaurant",
  //   action: "Menu",
  //   label: "Res Name", // optional
  //   value: 99, // optional, must be a number
  //   nonInteraction: true, // optional, true/false
  //   transport: "xhr", // optional, beacon/xhr/image
  // });
  //   // ReactGA.send("pageview");
  //   console.log(location.pathname);
  // }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  // const GA_TRACKING_ID = "G-69TBD266W7"; // G-69TBD266W7
  // ReactGA.initialize(GA_TRACKING_ID);
  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  // }, []);

  // console.log(id);



  // Logout functionality
  const logout = () => {
    localStorage.clear();
    setUser(null);
    navigate('/login');
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES, {
    variables: { slug },
  });
  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;
  // console.log(data)
  if (data.restaurants.data.length > 0) {
    // const { restaurant } = data.restaurants.data[0];
    const restaurant = data.restaurants.data[0];
    // const categoryData = restaurant.attributes.dishcategories.data;
    const ccolors = { backgroundColor: "#fff", color: "" };
    const txcolorc = "";
    // const titleStyles = { 
    //   transition: "all .3s",
    //   showlogo ? paddingLeft: "92px" : ,

    // };
    const categoryData = restaurant.attributes.dishes.data.filter((dish) =>
      (dish.attributes.menutype === 0) && (dish.attributes.isavailable === true)
    );
    const searchQuery = restaurant.attributes.dishes.data.filter((dish) =>
      (dish.attributes.name.toLowerCase().includes(query)) && (dish.attributes.isavailable === true)
    );

    return (
      <>

        <Container className="m-0 p-0 min-vh-100" style={ccolors}>
          <Helmet>
            <title>{restaurant.attributes.name}</title>
            <meta name="description" content={restaurant.attributes.description} />
            <meta property="og:title" content={restaurant.attributes.name} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={restaurant.attributes.description} />
            <meta property="og:url" content={`/https://www.foodple.com/restaurant/${restaurant.attributes.slug}`} />
            <meta property="og:image" content={`${process.env.REACT_APP_API_URL}${restaurant.attributes.image.data[0].attributes.url}`} />
            <meta property="og:site_name" content="FoodPle" />
          </Helmet>
          <Row className="p-0 m-0" style={{ height: "113px" }}>
            <div style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${restaurant.attributes.image.data[0]?.attributes.url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "#efefef" }}>
            </div>
          </Row>
          {/* src={`url(${process.env.REACT_APP_API_URL}${restaurant.attributes.image.data[0].attributes.url})`} */}
          <Navbar
            className="navbar-light sticky-top shadow-sm p-0 m-0 pt-4 pb-1 w-100 " style={ccolors} >

            <div className="d-flex justify-content-between position-relative w-100 top-0" style={{}}>

              {/* <NavbarBrand tag={Link} to={"/"} className="" > */}
              {/* <img src={logo} className="App-logo" alt="logo" /> */}
              <div className={`position-absolute rounded shadow-sm ${showlogo && 'invisible'}`} style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${restaurant.attributes.image.data[1]?.attributes.url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "80px", height: "80px", bottom: "0", left: "0", ransformOrigin: "center top 0px" }}>
                <div style={{ paddingBottom: "100%" }}></div>
              </div>
              <h5 className="d-block mw-100 overflow-hidden text-truncate fw-bolder mb-0 " style={{ paddingLeft: `${titlepadding}`, transition: "all .3s" }}>{restaurant.attributes.name}</h5>
              <div className="d-flex, align-items-center, justify-content-end p-0 m-0">
                {restaurant.attributes.facebook && <img className="" style={{ height: "24px" }} src="/images/facebook.png" onClick={() => window.open(restaurant.attributes.facebook)} alt="" />}
                {restaurant.attributes.twitter && <img className="" style={{ height: "24px" }} src="/images/twitter.png" onClick={() => window.open(restaurant.attributes.twitter)} alt="" />}
                {restaurant.attributes.instagram && <img className="" style={{ height: "24px" }} src="/images/instagram.png" onClick={() => window.open(restaurant.attributes.instagram)} alt="" />}


                {/* <Facebook /> <Instagram /> <Youtube /> */}

              </div>
              {/* </NavbarBrand> */}
              {/* <NavbarToggler onClick={toggleSidebar} className="me-2 float-start" /> */}
            </div>
            <Row>
              {/* <Col>
                <NavbarToggler onClick={toggleSidebar} className="border-0 float-start"
                // style={{width: "1em", height:"1em"}} 
                />
                <ArrowRepeat className="mx-2" onClick={() => window.location.reload(false)} />
              </Col> */}
              <Col className="d-flex align-middle pt-3" >

                <NavbarToggler onClick={toggleSidebar} className="border-0 float-start m-0 p-0 pb-2 pe-2" />

                <InputGroup >
                  <InputGroupText className="bg-transparent p-2" style={{ height: "2em" }} >
                    <Search style={{ width: "1em", height: "1em" }} />
                  </InputGroupText>
                  <Input
                    id="dishSearch"
                    name="search"
                    placeholder="type dish name"
                    type="search"
                    onChange={e => updateQuery(e.target.value.toLocaleLowerCase())}
                    value={query}
                    style={{ height: "2em" }}
                  />
                </InputGroup>

                <Dropdown toggle={() => handleCatmenushow()} isOpen={catmenushow} >
                  {/* <InputGroupText> */}
                  <DropdownToggle className="bg-transparent border-0 text-dark p-0">
                    <CardHeading className="mx-2 pb-1" style={{ width: "27px", height: "27px" }} />
                  </DropdownToggle>
                  {/* </InputGroupText> */}
                  <DropdownMenu
                  >
                    {/* <DropdownItem key={"All"} onClick={() => updateQuery("")}>
                      All Categories
                    </DropdownItem> */}
                    {categoryData.map((dishcategory, index) => (
                      // <DropdownItem key={index} onClick={() => updateQuery(dishcategory.attributes.name.toLocaleLowerCase())}>
                      <DropdownItem key={index} > <span>
                        <HashLink className="" to={'#' + dishcategory.attributes.name.replace(/\s+/g, '-').toLowerCase()} scroll={scrollWithOffset} >{dishcategory.attributes.name}</HashLink>
                        {/* {dishcategory.attributes.name} */}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

                <XLg className="" onClick={() => updateQuery("")} style={{ width: "27px", height: "27px" }} />
                <ArrowRepeat className="mx-2" onClick={() => window.location.reload(false)} style={{ width: "27px", height: "27px" }} />
              </Col>
              <Offcanvas
                toggle={toggleSidebar}
                isOpen={sidebarIsOpen}
              // className="w-75"
              >
                <OffcanvasHeader toggle={toggleSidebar} className="border-bottom">
                  {restaurant.attributes.name}

                </OffcanvasHeader>
                <OffcanvasBody >

                  <Nav vertical>
                    {/* <p>User Menu</p> */}
                    {/* <hr /> */}
                    <p className="text-center px-2">{restaurant.attributes.description}</p>
                    {/* <p>Menu Categories</p> */}

                    {categoryData.map((dishcategory, index) => (
                      <NavItem key={index}>
                        <HashLink onClick={() => { toggleSidebar(); }} to={'#' + dishcategory.attributes.name.replace(/\s+/g, '-').toLowerCase()} >{dishcategory.attributes.name}</HashLink>
                        {/* <NavLink className="pe-auto" onClick={() => { updateQuery(dishcategory.attributes.name.toLocaleLowerCase()); toggleSidebar(); }} >
                          {dishcategory.attributes.name}
                        </NavLink> */}
                      </NavItem>
                    ))}
                    <hr />
                    {
                      user ?
                        <>
                          <NavItem className="">
                            <NavLink tag={Link} to={"/myaccount"} >
                              My Account
                            </NavLink>
                          </NavItem>

                          <NavItem>
                            <NavLink tag={Link} to={"/login"}>
                              <span onClick={logout}>Logout</span>
                            </NavLink>
                          </NavItem>
                          {/* <NavItem>
                            <NavLink tag={Link} to={"/myrestaurant"}>
                              Restaurant Admin Portal
                            </NavLink>
                          </NavItem> */}
                        </>
                        :
                        <NavItem className="">
                          <NavLink tag={Link} to={"/login"}>
                            Login
                          </NavLink>
                        </NavItem>

                    }



                    {/* <NavItem>
                      <NavLink tag={Link} to={"/myrestaurant"}>
                        Show Directions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} to={"/myrestaurant"}>
                        Current Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} to={"/myrestaurant"}>
                        Timings
                      </NavLink>
                    </NavItem>
                    <p>Cusines</p>
                    <NavItem>
                      <NavLink tag={Link} to={"/myrestaurant"}>
                        Cusines
                      </NavLink>
                    </NavItem>
                    <p>Meal types & Specialities</p>
                    <div><Bookmark onClick={() => alert("Bookmark service is in maintenance")} /> Add to Favourites </div>
                    <Share className="ms-2" onClick={() => alert("Share service is in maintenance")}/>
                    <ArrowRepeat className="mx-2" onClick={() => window.location.reload(false)} />
                    <HouseFill className=" me-1" onClick={() => navigate('/')} />

                    <Link to={"/"}>Home</Link>
                    <NavItem>

                      My Account

                    </NavItem>


                    <strong>
                      Menu Items Menu Items
                    </strong> */}
                  </Nav>
                </OffcanvasBody>
              </Offcanvas>
            </Row>
          </Navbar>
          <Container className="px-0 m-0">
            {/* <h1 className="fw-bolder mb-0">{restaurant.attributes.name}
              <small className="float-end h5 mt-1 text-dark">
                <Bookmark onClick={() => alert("Bookmark service is in maintenance")} />
                <Share className="ms-2" onClick={() => alert("Share service is in maintenance")}/>
                <ArrowRepeat className="mx-2" onClick={() => window.location.reload(false)} />
                <HouseFill className=" me-1" onClick={() => navigate('/')} />
                <ThreeDotsVertical className=" me-1" onClick={() => navigate('')} />

              </small></h1> */}
            <div className="justify-content-center px-2 d-flex my-2">
              <small className="">{restaurant.attributes.landmark}</small>
              <img className="mx-2" style={{ height: "18px" }} src="/images/google-directions.jpg" onClick={() => window.open("https://www.google.com/maps/dir/?api=1&destination=" + restaurant.attributes.latitude + "," + restaurant.attributes.longitude)} alt="" />
              {/* <Map onClick={() => window.open("https://www.google.com/maps/dir/?api=1&destination=" + restaurant.attributes.latitude + "," + restaurant.attributes.longitude)} /> */}
            </div>
            <div className="justify-content-evenly px-2 d-flex small"> <div><PinMap />{restaurant.attributes.city} </div>
              {/* <div style={{height: "1.20em"}}>      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg> </div> */}
              <div><Telephone /> {restaurant.attributes.phone1} </div>
            </div>
            {
              restaurant.attributes.isactive ?
                <></>
                :
                <Row>
                  <Col className="border border-danger mx-3 rounded-2 text-danger py-2">
                    This restaurnat is configured as not Active at this time. Please check with restaurant authorities. Refresh page to get update.
                  </Col>
                </Row>
            }

            <Row className="my-2">
              <Col >
                {(restaurant.attributes.restauranttype === 0) ?
                  <RestaurantGroupList restaurant={searchQuery} />
                  :
                  <RestaurantMenu restaurant={searchQuery} currency={restaurant.attributes.currency} categoryData={restaurant.attributes.dishcategories.data} cartItems={cartItems} cartItemsVar={cartItemsVar} />
                }
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="d-flex text-center">
              {/* { restaurant.attributes.website && <div><Globe className="ms-3" /> {restaurant.attributes.website} </div> } */}
              <div className="pb-3 small" >

                {restaurant.attributes.address}  {restaurant.attributes.city} - {restaurant.attributes.pincode}
                <Files className="mx-2" onClick={() => { navigator.clipboard.writeText(restaurant.attributes.address + restaurant.attributes.city + "-" + restaurant.attributes.pincode) }} />
              </div>
              <div className="py-3 border-top">{restaurant.attributes.fssai}</div>

            </Row>
            <Row className="bg-light py-3">
              {/* <hr /> */}
              <p className="text-center text-muted small">
                Powered by <Link to={"/"}>FoodPle</Link>
              </p>
            </Row>
            {/* Cart Container - show up on adding items to cart */}
            {
              (cartItems.length > 0) &&
              <Container className="fixed-bottom ">
                <div className="py-4 px-3">
                  <Button className="w-100"
                    onClick={() => handleCartshow()}
                  >
                    <div className="d-flex justify-content-evenly">
                      <div className="bg-light text-dark rounded-2 px-2"> {(cartItems.reduce((totQty, item) => totQty = totQty + parseInt(item.qty), 0))}</div>
                      <div className="w-50">view cart</div>
                      <div className="bg-light text-dark rounded-2 px-2 "><small>{restaurant.attributes.currency}</small> {(cartItems.reduce((totCost, item) => totCost = totCost + (parseInt(item.qty) * parseFloat(item.dishdprice)), 0))} </div>
                    </div>
                  </Button>
                </div>
              </Container>
            }

            {/* Cart Section */}
            {
              cartshow && (
                <Modal
                  centered
                  fullscreen="sm"
                  fade={true}
                  isOpen={cartshow}
                  toggle={handleCartshow}
                  className="min-vh-100 bg-light "
                >
                  {
                    cartItems.length <= 0 ? handleCartshow() : ""
                  }
                  <ModalBody>
                    {/* <Row> */}
                    {/* <Button outline bssize="sm" className="w-auto py-0 px-1" onClick={() => handleCartshow()}> <ArrowBarLeft /> Back to Menu </Button> */}
                    {/* </Row> */}
                    <Row className="  w-100">
                      <div className="d-flex justify-content-evenly py-2 w-100" style={{ background: "rgba(10, 60, 161)"}}>
                        <Button outline bssize="sm" className="w-auto py-0 px-1 text-primary bg-light" onClick={() => handleCartshow()}> <ArrowBarLeft className="mb-1" /></Button>

                        <div className="w-50 text-light fw-bolder ps-2">Your Order</div>
                        <div className="text-light px-2">{(cartItems.reduce((totQty, item) => totQty = totQty + parseInt(item.qty), 0))} items </div>
                        <div className="bg-light text-dark rounded-2 px-2 "><small>{restaurant.attributes.currency}</small> {(cartItems.reduce((totCost, item) => totCost = totCost + (parseInt(item.qty) * parseFloat(item.dishdprice)), 0))} </div>
                      </div>
                      <div className="text-center">You saved <small>{restaurant.attributes.currency}</small> {(cartItems.reduce((totSaving, item) => totSaving = totSaving + (parseInt(item.qty) * (parseFloat(item.dishprice) - parseFloat(item.dishdprice))), 0))}</div>
                    </Row>

                    <Row className="my-3">
                      {cartItems.map((item, index) => (
                        <Card id={item.dishid}>
                          <div className="d-flex">
                            <div>{item.dishname}</div>
                            <div className="ms-auto">
                              {restaurant.attributes.currency} {parseInt(item.qty) * parseFloat(item.dishdprice)}
                            </div>
                          </div>
                          <div className="d-flex small my-2">
                            <div className='w-50 ps-2'>
                              {item.dishvariant} <span className="float-end"> <span className="fw-lighter text-decoration-line-through"> {((parseFloat(item.dishprice) - parseFloat(item.dishdprice)) > 0) ? parseFloat(item.dishprice) : ""} </span> {restaurant.attributes.currency}{parseFloat(item.dishdprice)}{" x "}</span>
                            </div>
                            <div className='d-inline-flex w-25 px-1'>
                              <InputGroup size="sm" className=""  >
                                <Button outline
                                  bssize="sm"
                                  className="ms-1 p-0 px-1"
                                  onClick={() => {
                                    let newcartItems = cartItemsVar();
                                    if (parseInt(item.qty) > 1) {
                                      newcartItems[index].qty = parseInt(newcartItems[index].qty) - 1;
                                    } else {
                                      newcartItems.splice(index, 1);
                                    }
                                    cartItemsVar([...newcartItems]);
                                  }}
                                >
                                  {(parseInt(item.qty) > 1) ? <DashLg /> : <Trash className='text-danger bg-light' /> }
                                </Button>
                                <Input
                                  id="dishQty"
                                  name="dishQty"
                                  placeholder="0"
                                  type="text"
                                  disabled
                                  bssize="sm"
                                  className="text-center p-0 px-1"
                                  value={item.qty}
                                />
                                <Button outline
                                  bssize="sm" className="me-1  p-0 px-1"
                                  onClick={() => {
                                    let newcartItems = cartItemsVar();
                                    newcartItems[index].qty = parseInt(newcartItems[index].qty) + 1;
                                    cartItemsVar([...newcartItems]);
                                  }}
                                >
                                  <PlusLg />
                                </Button>
                              </InputGroup>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </Row>
                    <Row className="d-flex justify-content-evenly">
                      <p className='text-center'>select service type</p>
                      <Button disabled className="bg-dark w-auto">Take Away</Button>
                      <Button disabled className="bg-dark w-auto">Delivery</Button>
                      <Button className="bg-dark w-auto"
                        onClick={() => { cartItemsVar([]); handleCartshow(); }}
                      >
                        Clear Cart</Button>
                    </Row>
                  </ModalBody>
                </Modal>
              )
            }
          </Container>
        </Container>
      </>
    );
  }
  else {
    return (<NotFound404 />);
  }
}
export default RestaurantPage