import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Container, Table, Row, Badge, Modal, ModalBody, Spinner } from "reactstrap";
import { PencilSquare, ToggleOff, ToggleOn, PlusSquareFill } from 'react-bootstrap-icons';
import { UPDATE_DISH_AVAILABILITY } from '../utils/mutation';
//import moment from "moment";
import { EditMenuItem, AddMenuItem, Spinnerx, NotFound404 } from '.';
import { GET_RESTAURANT_DISHES_BYID, DISH_BY_ID_QUERY } from "../utils/queries";

// Update dish availability in dishes/ MenuItems table
function OptionTableField({ value, itemID }) {
  const [status, setStatus] = useState(value);
  const [updateDish, { loading }] = useMutation(
    UPDATE_DISH_AVAILABILITY, {
    refetchQueries: [
      {
        query: DISH_BY_ID_QUERY,
        variables: { id: itemID },
      },
    ],
  }
  );

  async function handleChange(value) {
    await updateDish({
      variables: {
        id: itemID,
        data: {
          isavailable: value,
        },
      },
    });
    setStatus(value);
  }

  if (loading) {
    return <p className="small">Updating...</p>;
    //   return <><Spinner
    //   color="info"
    //   size="sm"
    //   >
    // </Spinner></>
  }

  return (<>
    {
      status ?
        <ToggleOn className="text-success h3" onClick={() => handleChange(!status)} />
        :
        <ToggleOff className="text-secondary h3" onClick={() => handleChange(!status)} />
    }
  </>

  );
}


function MenuItems(props) {
  // const tableData = props.restaurantData.data.attributes.dishes.data;
  // let restaurantId = props.restaurantID;
  // console.log(tableData);
  // console.log(restaurantId);

  const [selectedItemId, setSelectedItemId] = useState(null);
  //  const { user } = useContext(UserContext);
  const [modalshow, setModalshow] = useState(false);
  const [modaltype, setModaltype] = useState();
  const handleModalShow = () => setModalshow(!modalshow);
  const restaurantID = props.restaurantID;
  // console.log(restaurantID);
  const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES_BYID, {
    variables: { id: restaurantID },
    // variables: { restaurantID },
  });
  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;
  if (data.restaurant.data) {
    const { restaurant } = data;
// console.log(data);
    const tableData = data.restaurant.data.attributes.dishes.data;
    const categoryData = data.restaurant.data.attributes.dishcategories.data;
    // console.log(categoryData);
    const tableHeader = [
      // "ID",
      "Menu#",
      "Dish Name",
      // "Price",
      "Dish Type",
      "Serving",
      // "Updated On"
    ];
    // Open Edit Items dialog / modal
    function handleShowModal(itemID) {
      // setIsVisible(true);
      if (itemID) {
        setSelectedItemId(itemID);
        setModaltype("edit")
      }
      else setModaltype("add");
      handleModalShow();
    }

    return (
      <Container className="bg-white ">
        <Row>
          <div className="mt-3 "> Menu contains {tableData.length} items
            <PlusSquareFill className="h3 float-end text-primary" onClick={() => handleShowModal()} />
          </div>
        </Row>
        <Row>

          <Table responsive>
            <thead>
              <tr>
                {tableHeader.map((header, index) => (
                  <th key={index}>
                    {header}
                  </th>
                ))}
                <th className="visually-hidden">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {tableData.map((dish) => (
                <tr key={dish.id} className={` ${ (dish.attributes.menutype === 0) && 'bg-light'}`}>
                  {/* <td>{dish.id}</td> */}
                  <td>{dish.attributes.menuorderid}</td>
                  <td className={` ${ (dish.attributes.menutype === 0) && 'fw-bolder'}`}>{dish.attributes.name}</td>
                  {/* <td className="d-none d-md-block ">{dish.attributes.description.substring(0, 50)}...</td> */}
                  {/* <td >{dish.attributes.description.substring(0, 50)}...</td> */}
                  {/* <td >{dish.attributes.dishcategory.data?.attributes.name}</td> */}
                  {/* <td>{dish.attributes.price}</td> */}
                  <td>
                    <span className={` ${ (dish.attributes.menutype === 0) && 'invisible'}`}>
                    {dish.attributes.isveg ?
                      <Badge color="success"> Veg </Badge>
                      :
                      <Badge color="danger"> Non-Veg </Badge>
                    } </span>
                  </td>
                  <td>
                    <OptionTableField value={dish.attributes.isavailable} itemID={dish.id} />
                  </td>
                  
                  {/* <td>
                     {moment(dish.attributes.updatedAt).format("MMM Do YY")} 
                  </td> */}
                  <td>
                    {/**  <PencilSquare className="h4" onClick={() => handleShowModal(dish.id)} /> */}
                    <PencilSquare className="h4" onClick={() => handleShowModal(dish.id)} />

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {modalshow && (
            <Modal
              centered
              fade={false}
              isOpen={modalshow}
              toggle={handleModalShow}
            //  toggle={function noRefCheck(){}}
            >
              {/**       <ModalHeader toggle={handleModalShow}>
              
            </ModalHeader> */}
              <ModalBody>
                {(modaltype === "edit") ?
                  <EditMenuItem editdishId={selectedItemId} restaurantId={restaurantID} categoryData={categoryData} setModalshow={setModalshow} />
                  : <AddMenuItem restaurantId={restaurantID} categoryData={categoryData} setModalshow={setModalshow} />}
              </ModalBody>


            </Modal>
          )}
        </Row>
      </Container>
    )
  }
}

export default MenuItems