import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { UserContext } from '../utils/UserContext';



function LoginRedirect(props) {
    const { user, setUser } = useContext(UserContext);
    const [text, setText] = useState('Loading...');
    const backendUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    
// console.log(params);  
    useEffect(() => {
      // Successfully logged with the provider
      // Now logging with strapi by using the access_token (given by the provider) in props.location.search
      fetch(`${backendUrl}/api/auth/${params.providerName}/callback${location.search}`)
        .then(res => {
            // console.log(res);
          if (res.status !== 200) {
            throw new Error(`Couldn't login to FoodPle with ${params.providerName}. Status: ${res.status}`);
          }
          
          return res;
        })
        .then(res => res.json())
        .then(res => {
          // console.log(res);
          // Successfully logged with Strapi
          // Now saving the jwt to use it for future authenticated requests to Strapi
        //   setUser({ token: login.jwt, userID: login.user.id });
          setUser({ token: res.jwt, userID: res.user.id });
       // localStorage.setItem("jwt", data.login.jwt)

        //   localStorage.setItem('jwt', res.jwt);
        //   localStorage.setItem('username', res.user.username);
          setText('You have been successfully logged in. You will be redirected in a few seconds...');
          setTimeout(() => navigate('/'), 3000); // Redirect to homepage after 3 sec
        })
        .catch(err => {
          console.log(err);
          setText('An error occurred, please see the developer console.')
        });
    }, [location.search, params.providerName]);
  
    return <p>{text}</p>
  
}

export default LoginRedirect