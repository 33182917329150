import {gql} from "@apollo/client";


export const LOGIN_USER = gql`
mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`

export const SIGNUP_USER = gql`
mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPLOAD_IMAGE_MUTATION = gql`
  mutation UploadImageMutation(
    $file: Upload!
    $ref: String!
    $refId: ID!
    $field: String!
  ) {
    upload(file: $file, ref: $ref, refId: $refId, field: $field) {
      data {
        attributes {
          name
        }
      }
    }
  }
`;

export const CREATE_RESTAURANT = gql`
  mutation CreateRestaurant($data: RestaurantInput!) {
    createRestaurant(data: $data) {
      data {
        id
      }
    }
  }
`;

export const CREATE_RESTAURANT_STAFF = gql`
  mutation CreateRestaurantstaff($data: RestaurantstaffInput!) {
    createRestaurantstaff(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_RESTAURANT_STAFF = gql`
  mutation UpdateRestaurantstaff ($id: ID!, $data: RestaurantstaffInput!) {
  updateRestaurantstaff (id: $id data: $data) {
    data {
      id
    }
  }
}
`;

export const CREATE_DISH = gql`
  mutation CreateDishInRestaurant($data: DishInput!) {
    createDish(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_DISH_AVAILABILITY = gql`
  mutation UpdateDishStatus ($id: ID!, $data: DishInput!) {
  updateDish (id: $id data: $data) {
    data {
      id
    }
  }
}
`;

export const CREATE_DISH_CATEGORY = gql`
  mutation CreateDishCategory($data: DishcategoryInput!) {
    createDishcategory(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_DISH_CATEGORY = gql`
  mutation UpdateDishCategory ($id: ID!, $data: DishcategoryInput!) {
  updateDishcategory (id: $id data: $data) {
    data {
      id
    }
  }
}
`;

export const UPDATE_RESTAURANT = gql`
  mutation UpdateRestaurant ($id: ID!, $data: RestaurantInput!) {
  updateRestaurant (id: $id data: $data) {
    data {
      id
    }
  }
}
`;