import React, { useState } from "react";
// import { useQuery } from "@apollo/client";
//import Spinner from '../components/Spinner';
// import { RESTAURANTS_LIST } from "../utils/queries";
import { useNavigate } from 'react-router-dom';
// import { Circles } from 'react-loader-spinner';
import { Header, RestaurantList } from '../components';

// import { Button, Alert } from "reactstrap";

import { Container, Col, Input, InputGroup, InputGroupText, Row, FormGroup, FormText } from "reactstrap";
import { Search, HouseFill } from 'react-bootstrap-icons';



function Restaurant() {
  const [query, updateQuery] = useState("");
  const navigate = useNavigate();
  return ( <>
  {/* <Container fluid className="m-0 p-0 "> */}
    <Header />
  {/* </Container> */}
    <Container>
      <Row className="m-3">
        <Col >

    <InputGroup >
    <InputGroupText>
    <Search />
    </InputGroupText>
    <Input
      id="restaurantSearch"
      name="search"
      placeholder="type restaurant name"
      type="search"
      onChange={e => updateQuery(e.target.value.toLocaleLowerCase())}
                value={query}
    />
  <HouseFill className=" mx-2 mt-2 h4" onClick={() => navigate('/')} />
  </InputGroup>

        <FormGroup>
    
    <FormText>
      Scan QR Code to access specific restaurant. 
    </FormText>
  </FormGroup>
          
        </Col>
      </Row>
      
        <RestaurantList search={query} />
      
    </Container>
    </>
  );
}

export default Restaurant;

