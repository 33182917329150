import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../utils/UserContext';
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from 'uuid';
// import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { Container, Col, Input, Row, Button, Label, FormGroup, Form } from "reactstrap";
// import { PencilSquare, ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import { CREATE_RESTAURANT, UPLOAD_IMAGE_MUTATION, CREATE_RESTAURANT_STAFF } from '../utils/mutation';
import { isRegexValid, checkURLRegex } from '../utils/helpers';
// import { DISH_BY_ID_QUERY, GET_RESTAURANT_DISHES } from "../utils/queries";
import Header from './Header';


const INITIAL_ERROR_STATE = {
  name: false,
  description: false,
  city: false,
  slug: false,
  currency: false,
  website: false,
};

const INITIAL_FORM_STATE = {
  name: "",
  description: "",
  image: "",
  city: "",
  slug: "",
  currency: "₹",
  website: "",
  staffname: "",
  staffemail: "",
  staffphone: "",
  tos: false
};

function AddRestaurant() {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [showwiz, setShowwiz] = useState(1);
// console.log(uuidv4());
  useEffect(() => {
    if (!user) {
      setShowwiz(0);
    }
  }, [user]);

  // useEffect(() => {
  //   loadCaptchaEnginge(6); 
  // }, );  

  const [createRestaurant, { loading, error }] = useMutation(
    CREATE_RESTAURANT
  );

  const [createRestaurantstaff, { loading: loadingStaffUpdate, error: errorStaffUpdate }] = useMutation(
    CREATE_RESTAURANT_STAFF
  );

  const [uploadImage, { loading: loadingUpload, error: errorUpload }] =
    useMutation(UPLOAD_IMAGE_MUTATION, {
      // refetchQueries: [
      //   {
      //     query: PROJECTS_BY_USER_QUERY,
      //     variables: {
      //       id: {
      //         eq: user.userID,
      //       },
      //     },
      //   },
      // ],
    });

  function validateUrlErrors(event) {
    if (event.target.value.length !== 0) {
      if (isRegexValid(event.target.value, checkURLRegex)) {
        setFormError((prevState) => ({
          ...prevState,
          [event.target.name]: false,
        }));
      } else {
        setFormError((prevState) => ({
          ...prevState,
          [event.target.name]: true,
        }));
      }
    } else {
      setFormError((prevState) => ({
        ...prevState,
        [event.target.name]: false,
      }));
    }
  }

  function formValidation() {
    let hasError = false;

    //   if (file === null) {
    //     setFormError((prevState) => ({ ...prevState, file: true }));
    //     hasError = true;
    //   } else {
    //     setFormError((prevState) => ({ ...prevState, file: false }));
    //   }

    if (formData.name.length === 0) {
      setFormError((prevState) => ({ ...prevState, projectName: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, projectName: false }));
    }

    if (formData.description.length < 5) {
      setFormError((prevState) => ({ ...prevState, projectDescription: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({
        ...prevState,
        projectDescription: false,
      }));
    }

    return hasError;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleNavigatelink(link) {

    navigate(link, { state: { returnpath: '/register-restaurant' } });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!formValidation() && formData.tos) {
      let uuidv4val = uuidv4();
      let nameval = formData.name.trim();
      let slugValue = nameval.replace(/\s+/g, '-').toLowerCase() + "-" + uuidv4val;
      // console.log(slugValue);
      const response = await createRestaurant({
        variables: {
          data: {
            name: nameval,
            ruuid: uuidv4val,
            description: formData.description,
            city: formData.city,
            slug: slugValue,
            currency: formData.currency,
            image: null,
            website: formData.website,
          },
        },
      });
      //   console.log(response.data.createRestaurant.data);
      const { id: restaurantID } = response.data.createRestaurant.data;
      //   console.log(restaurantID);
      // await uploadImage({
      //   variables: {
      //     file: file,
      //     refId: restaurantID,
      //     ref: "api::project.project",
      //     field: "featuredImage",
      //   },
      // });

      await createRestaurantstaff({
        variables: {
          data: {
            staffrole: parseInt(1),
            userid: user.userID,
            restaurant: parseInt(restaurantID),
            rsuuid: uuidv4(),
            username: formData.staffname,
            emailaddress: formData.staffemail,
            phone: formData.staffphone,
          },
        },
      });

      setShowwiz(2);

    }
  }

  if (error || errorUpload || errorStaffUpdate) return <p>{error.message}</p>;
  if (showwiz === 0) {
    return (
      <>
        <Container className="bg-light  ">
          <Header />
        <Row >
            <h2 className="text-center text-dark pt-2 m-0">Register your restaurant</h2>
            <p className="text-center text-muted small mb-1">No credit card required. Deregister at anytime</p>
          </Row>

          <Row className=" d-flex align-items-center justify-content-center m-1">
            <Col className=" col-md-4 rounded shadow bg-white px-4 py-3 flex ">
              <h6 className="text-muted">Register your Restaurant in 3 easy steps. </h6>
              {/* <h5 className="text-muted">Sign Up - Register - Configure </h5> */}
              <ol>
                <li>Login with FoodPle account</li>
                <li>Regiser Restaurant</li>
                <li>Configure Information, Go Live</li>
              </ol>
              <Button type="submit" className="bg-primary rounded-3 shadow-none w-100 " onClick={() => handleNavigatelink('/login')}>Login to proceed</Button>
              <p className="mt-3 mb-1 text-muted small text-center">If you don't have account,  </p>

              <Button type="submit" className="bg-secondary rounded-3 shadow-none w-100" onClick={() => handleNavigatelink('/signup')}>Signup Here</Button>

            </Col>
          </Row>
        </Container>
      </>
    );
  }
  if (showwiz === 1) {
    return (
      <>
        <Container className="bg-light  ">
        <Header />
          <Row >
            <h2 className="text-center text-dark pt-2 m-0">Register your restaurant</h2>
            <p className="text-center text-muted small mb-1">No credit card required. Deregister at anytime</p>
          </Row>

          <Row className=" d-flex align-items-center justify-content-center m-1">
            <Col className=" col-md-4 rounded shadow bg-white px-4 py-3 flex ">
              {/* <h6 className="text-muted">Add Restaurant </h6> */}
              {
                error &&
                <div className="text-danger mb-2 text-center  py-1 rounded-3 border border-danger">{error.message}</div>
              }

              <Form inline onSubmit={handleSubmit}>
                
              <p className="bg-light py-2 h5 px-1 ">Restaurant Information</p>
                <FormGroup >
                  <Label
                    for="restaurantName"
                  hidden
                  >
                    Restaurant Name
                  </Label>
                  <Input
                    id="restaurantName"
                    name="name"
                    placeholder="Restaurant Name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {' '}<FormGroup>
                  <Label
                    for="restaurantDescription"
                    hidden
                  >
                    Restaurant Description
                  </Label>
                  <Input
                    id="restaurantDescription"
                    name="description"
                    placeholder="Restaurant Description"
                    type="textarea"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {' '}<FormGroup>
                  <Label
                    for="restaurantCity"
                    hidden
                  >
                    Restaurant City
                  </Label>
                  <Input
                    id="restaurantCity"
                    name="city"
                    placeholder="Restaurant City"
                    type="text"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                
                <p className="bg-light py-2 h5 px-1 ">Administrator Information</p>
                {' '}<FormGroup>
                  <Label
                    for="adminName"
                    hidden
                  >
                    Admin Name
                  </Label>
                  <Input
                    id="adminName"
                    name="staffname"
                    placeholder="Admin Name"
                    type="text"
                    value={formData.staffname}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {' '}<FormGroup>
                  <Label
                    for="adminEmail"
                    hidden
                  >
                    Admin Email
                  </Label>
                  <Input
                    id="adminEmail"
                    name="staffemail"
                    placeholder="Admin Email"
                    type="text"
                    value={formData.staffemail}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {' '}<FormGroup>
                  <Label
                    for="adminPhone"
                    hidden
                  >
                    Admin Phone
                  </Label>
                  <Input
                    id="adminPhone"
                    name="staffphone"
                    placeholder="Admin Phone"
                    type="text"
                    value={formData.staffphone}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {' '}
                <FormGroup className="d-flex">
                <Input
                    id="authorizedPerson "
                    type="checkbox"
                    checked={formData.tos}
                    onChange={() =>  
                      setFormData({
                        ...formData,
                        tos: !formData.tos   
                      })
                    }
                    
                  />
                  {/* {console.log(formData.tos)} */}
                <Label for="authorizedPerson" className="ps-2 small">I acknowledge that I'm authorized person to register this restaurant.</Label>
                </FormGroup>
                {' '}
                <p className="text-center text-muted">By registering restaurant, you adhere to the <br /><Link to={"/terms-and-conditions"} >Terms Of Service</Link> and <Link to={"/privacy-policy"} > Privacy Policy</Link>.</p>
                <FormGroup className="d-flex  justify-content-evenly pt-2">
                {/* <LoadCanvasTemplate /> */}
                <Button type="submit" disabled={!formData.tos} className="bg-primary rounded-3 shadow-none fw-bold">Add Restaurant</Button>
                <Button className="bg-transparent rounded-3 fw-bold text-muted"  onClick={() => navigate(-1)}>Cancel</Button>
                </FormGroup>
              </Form>

            </Col>
          </Row>
        </Container>



      </>
    );
  }
  if (showwiz === 2) {
    return (
      <>
        <Container className="bg-light  ">
        <Header />
          <Row >
            <h2 className="text-center text-success py-2 fst-italic">Congratulations!</h2>
            <h5 className="text-center text-muted">Welcome to FoodPle Community </h5>
            <p className="text-center text-muted ">you are almost ready to Go Live</p>
          </Row>

          <Row className=" d-flex align-items-center justify-content-center m-1">
            <Col className=" col-md-4 rounded shadow bg-white px-4 py-3 flex ">
              <h6 className="text-muted">Next Steps - Update Restaurant Info </h6>
              {/* <h5 className="text-muted">Sign Up - Register - Configure </h5> */}
              <ol>
                <li>Update Restaurant Settings</li>
                <li>Add Dishes</li>
                <li>Enjoy! FoodPle Benefits</li>
              </ol>
              <p className="text-center text-muted">Open this help manual for instructions in configuring your restaurant</p>
              <Button type="submit" className="bg-primary rounded-3 shadow-none w-100 " onClick={() => handleNavigatelink('/myrestaurant')}>Continue - Restaurant Admin Portal</Button>


            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddRestaurant