import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../utils/UserContext';
import { Spinnerx, RestaurantPortal } from '../components';
import { useQuery } from "@apollo/client";
import { Container, Table, Row } from "reactstrap";
import { PencilSquare } from 'react-bootstrap-icons';
import { GET_RESTAURANT_LIST_BY_USER_ID_QUERY } from "../utils/queries";



function RestaurantVendor() {
  // const [showtab, setShowtab] = useState("0");
  const [restaurantId, setRestaurantId] = useState();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  let id = 0;
// console.log(user);
useEffect(() => {
  // console.log("in use effect");
  if(!user) {
  navigate('/login');
  // console.log("user not logged in");
}
}, [user, navigate]);

if(user) {
  id = user.userID;
}


  const { loading, error, data } = useQuery(GET_RESTAURANT_LIST_BY_USER_ID_QUERY,
    { variables: { "userid": id } },
    // { variables: { "userid": "2" } },
    // { skip: id === "0" },
    // variables: { "userid":"1" },
  );
  if (error) return "Error Loading Restaurant(s) Info";
  if (loading) return <Spinnerx message="Fetching..." />;
  // console.log(data);
  if (data.restaurantstaffs.data.length > 0) {
    if (data.restaurantstaffs.data.length === 1) {
      // setRestaurantId(data.restaurantstaffs.data[0].attributes.restaurant.data.id);
      // const restaurantID = data.restaurantstaffs.data[0].attributes.restaurant.data.id;
      return (<RestaurantPortal restaurantID={data.restaurantstaffs.data[0].attributes.restaurant.data.id} />);
    }
    else {
      if (!restaurantId) {
        const tableData = data.restaurantstaffs.data;
        const tableHeader = [
          "Restaurant Name",
          "Location",
          "Role"
        ];
        return (
          <Container className="bg-white m-3 p-0">
            <Row>
              <div className="py-3 "> you have access to {tableData.length} restaurants. Select the restaurant you want to manage.
              </div>
            </Row>
            <Row>
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeader.map((header, index) => (
                      <th key={index}>
                        {header}
                      </th>
                    ))}
                    <th className="visually-hidden">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {tableData.map((staff) => (
                    <tr key={staff.id}>
                      <td>{staff.attributes.restaurant.data.attributes.name}</td>
                      <td>{staff.attributes.restaurant.data.attributes.city}</td>
                      <td>
                        {(staff.attributes.staffrole === 1)
                          ? "Admin"
                          : "Staff"
                        }
                      </td>
                      <td>
                        <PencilSquare className="h4" onClick={() => setRestaurantId(staff.attributes.restaurant.data.id)} />
                        </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </Container>
        )
      }

      // Render the restaurant portal component
      return (<RestaurantPortal restaurantID={restaurantId} />);
    }
  }
  else {
    return "You are not admin for any restaurants. Click here to Register a Restaurant into this platform";

  }

  // return (
  //   <></>
  // );
}

export default RestaurantVendor