import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { MenuItems, RestaurantSettings, RestaurantDashboard, Spinnerx, RestaurantUsers, RestaurantSupport } from '.';
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { GET_RESTAURANT_DISHES_BYID } from "../utils/queries";
// import MenuCategory from './MenuCategory';
import Header from './Header';


function RestaurantPortal(props) {
  const [showtab, setShowtab] = useState("0");
  const restaurantID = props.restaurantID;
  // console.log(restaurantID);
  const { loading, error, data } = useQuery(GET_RESTAURANT_DISHES_BYID, {
    variables: { id: restaurantID },
    // variables: { restaurantID },
  });
  if (error) return "Error Loading Dishes";
  if (loading) return <Spinnerx message="Fetching..." />;
  // console.log(data)
  if (data.restaurant.data) {
    const { restaurant } = data;
    // console.log(restaurant);
    const tabMenu = [
      "Dashboard",
      "Manage Menu",
      // "Menu Category",
      "Users",
      "Settings",
      "Support",
    ];

    const handleTabClick = (tabid) => {
      if (tabid === showtab) {
        return;
      }
      setShowtab(tabid);
    };


    return (

      <Container className="p-0">
        <Header />
        <h1>{restaurant.data.attributes.name}</h1>
        <Row className="my-2 ">
          <Col >
            <Nav tabs>
              {tabMenu.map((menu, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={showtab === `${index}` ? "active" : null}
                    onClick={() => handleTabClick(`${index}`)}
                  >
                    {menu}
                  </NavLink>
                </NavItem>
              ))}

            </Nav>
            <TabContent activeTab={showtab}>
              <TabPane tabId="0">
                <RestaurantDashboard restaurantID={restaurantID} />
              </TabPane>
              <TabPane tabId="1">
                {/* <MenuItems restaurantData={restaurant, restaurantID} /> */}
                <MenuItems restaurantID={restaurantID} />
              </TabPane>
              {/* <TabPane tabId="2">
                <MenuCategory restaurantID={restaurantID} />
              </TabPane> */}
              <TabPane tabId="2">
                <RestaurantUsers restaurantID={restaurantID} />
              </TabPane>
              <TabPane tabId="3">
                <RestaurantSettings restaurantID={restaurantID} />
              </TabPane>
              <TabPane tabId="4">
                <RestaurantSupport />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>

    );
  }
}
export default RestaurantPortal